<template>
  <div>
    <h1>OTOs</h1>

    <!-- Progress bar -->
    <v-progress-linear
      v-if="loading"
      :value="progress"
      color="blue"
      height="8"
      class="mb-2"
      striped
      indeterminate
      :active="!progress"
    >
      <template v-if="progress">{{ progress }}%</template>
    </v-progress-linear>

    <div class="d-flex flex-row">
      <!-- Treeview -->
      <div class="d-flex flex-column flex-1">
        <v-treeview
          :items="treeItems"
          item-key="id"
          open-all
          @update:active="onSelect"
        >
          <template #prepend="{ item }">
            <span :style="{ color: getColor(item.classification) }">
              {{ item.id }}
            </span>
          </template>
        </v-treeview>
      </div>

      <!-- Details Panel -->
      <div class="d-flex flex-column flex-3 p-3" v-if="selectedDoc">
        <h3>Document ID: {{ selectedDoc.id }}</h3>
        <pre>{{ selectedDoc }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  limit,
  startAfter
} from "firebase/firestore";

export default {
  name: "SwitchOrdersOTOs",
  data() {
    return {
      treeItems: [],
      selectedDoc: null,
      loading: false,
      progress: 0
    };
  },
  mounted() {
    this.loadIDsInBatches();
  },
  methods: {
    getColor(classification) {
      switch ((classification || "").toUpperCase()) {
        case "BAD":
          return "red";
        case "GOOD":
          return "green";
        default:
          return "black";
      }
    },

    async loadIDsInBatches() {
      const db = getFirestore();
      const colRef = collection(db, "op_switchorders_otos");

      this.loading = true;
      const batchSize = 500;
      let lastVisible = null;
      let allItems = [];
      let loadedCount = 0;
      const estimatedTotal = 72635; // if you know the approx count

      while (true) {
        let q = query(colRef, limit(batchSize));
        if (lastVisible) {
          q = query(colRef, startAfter(lastVisible), limit(batchSize));
        }

        const snapshot = await getDocs(q);
        const docs = snapshot.docs;

        if (docs.length === 0) break;

        docs.forEach(docSnap => {
          allItems.push({
            id: docSnap.id,
            classification: docSnap.data().classification
          });
        });

        lastVisible = docs[docs.length - 1];
        loadedCount += docs.length;

        this.progress = Math.round((loadedCount / estimatedTotal) * 100);
        await this.$nextTick(); // allow Vue to update UI
      }

      this.treeItems = allItems;
      this.loading = false;
    },

    async onSelect(active) {
      const selectedId = Array.isArray(active) ? active[0] : active;
      if (!selectedId) return;

      const db = getFirestore();
      const docRef = doc(db, "op_switchorders_otos", selectedId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.selectedDoc = { id: docSnap.id, ...docSnap.data() };
      } else {
        this.selectedDoc = { id: selectedId, error: "Document not found" };
      }
    }
  }
};
</script>