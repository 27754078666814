<template>
  <div class="d-flex flex-row">
    <v-tabs v-model="tab" vertical class="max-width-250">
      <v-tab>Documents</v-tab>
      <v-tab>Operations</v-tab>
      <v-tab>Devices</v-tab>
      <v-tab>Procedures</v-tab>
      <v-tab disabled>Analyze</v-tab>
    </v-tabs>
    <v-divider vertical></v-divider>
    <v-tabs-items v-model="tab" class="flex-1">
      <!-- ---------------------- Background ---------------------- -->
      <v-tab-item>
        <div class="d-flex flex-row align-center mb-4">
          <v-text-field
            label="Search Documents"
            v-model="searchQuery.documents"
            @input="onSearchInput('documents')"
            @keyup.enter="onSearchSubmit('documents')"
            filled
            hide-details
            :prepend-inner-icon="isServerSearch['documents'] ? 'mdi-magnify' : 'mdi-filter-variant'"
            :append-icon="isServerSearch['documents'] ? 'mdi-arrow-right' : ''"
            @click:append="onSearchSubmit('documents')"
          ></v-text-field>
          <v-btn
            class="ml-4"
            text
            v-if="!loadAllActive['documents'] && !loading['documents']"
            @click="loadAll('documents')"
            :loading="loading.documents"
          >
            <v-icon left>mdi-refresh</v-icon>Load All
          </v-btn>
          <v-btn
            class="ml-4"
            text
            v-if="loading['documents']"
            @click="cancelLoading['documents'] = true"
          >
            <v-icon left>mdi-cancel</v-icon>Cancel Loading
          </v-btn>
        </div>
        <v-progress-linear
          v-if="loading['documents']"
          :value="loadProgress['documents']"
          color="black"
          height="10"
          class="my-4"
        ></v-progress-linear>
        <v-data-table
          :headers="headers.documents"
          :items="displayedData.documents"
          :items-per-page="loadAllActive.documents ? Math.min(totalItems.documents, 10000) : pageSize"
          :server-items-length="totalItems.documents"
          :page.sync="currentPage.documents"
          @click:row="onRowClick('documents', $event)"
          @update:page="onPageUpdate('documents', $event)"
          @update:items-per-page="onPageSizeChange('documents', $event)"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, { text: 'Max (10,000)', value: 10000 }]
          }"
        ></v-data-table>
      </v-tab-item>

      <!-- ---------------------- Operations ---------------------- -->
      <v-tab-item>
        <div class="d-flex flex-row align-center mb-4">
          <v-text-field
            label="Search Operations"
            v-model="searchQuery.operations"
            @input="onSearchInput('operations')"
            @keyup.enter="onSearchSubmit('operations')"
            filled
            hide-details
            :prepend-inner-icon="isServerSearch['operations'] ? 'mdi-magnify' : 'mdi-filter-variant'"
            :append-icon="isServerSearch['operations'] ? 'mdi-arrow-right' : ''"
            @click:append="onSearchSubmit('operations')"
          ></v-text-field>
          <v-btn
            class="ml-4"
            text
            v-if="!loadAllActive['operations'] && !loading['operations']"
            @click="loadAll('operations')"
            :loading="loading.operations"
          >
            <v-icon left>mdi-refresh</v-icon>Load All
          </v-btn>
          <v-btn
            class="ml-4"
            text
            v-if="loading['operations']"
            @click="cancelLoading['operations'] = true"
          >
            <v-icon left>mdi-cancel</v-icon>Cancel Loading
          </v-btn>
        </div>
        <v-progress-linear
          v-if="loading['operations']"
          :value="loadProgress['operations']"
          color="black"
          height="10"
          class="my-4"
        ></v-progress-linear>
        <v-data-table
          :headers="headers.operations"
          :items="displayedData.operations"
          :items-per-page="loadAllActive.operations ? Math.min(totalItems.operations, 10000) : pageSize"
          :server-items-length="totalItems.operations"
          :page.sync="currentPage.operations"
          @click:row="onRowClick('operations', $event)"
          @update:page="onPageUpdate('operations', $event)"
          @update:items-per-page="onPageSizeChange('operations', $event)"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, { text: 'Max (10,000)', value: 10000 }]
          }"
        ></v-data-table>
      </v-tab-item>
        
      <!-- ---------------------- Devices ---------------------- -->
      <v-tab-item>
        <div class="d-flex flex-row align-center mb-4">
          <v-text-field
            label="Search Devices"
            v-model="searchQuery.devices"
            @input="onSearchInput('devices')"
            @keyup.enter="onSearchSubmit('devices')"
            filled
            hide-details
            :prepend-inner-icon="isServerSearch['devices'] ? 'mdi-magnify' : 'mdi-filter-variant'"
            :append-icon="isServerSearch['devices'] ? 'mdi-arrow-right' : ''"
            @click:append="onSearchSubmit('devices')"
          ></v-text-field>
          <v-btn
            class="ml-4"
            text
            v-if="!loadAllActive['devices'] && !loading['devices']"
            @click="loadAll('devices')"
            :loading="loading.devices"
          >
            <v-icon left>mdi-refresh</v-icon>Load All
          </v-btn>
          <v-btn
            class="ml-4"
            text
            v-if="loading['devices']"
            @click="cancelLoading['devices'] = true"
          >
            <v-icon left>mdi-cancel</v-icon>Cancel Loading
          </v-btn>
        </div>
        <v-progress-linear
          v-if="loading['devices']"
          :value="loadProgress['devices']"
          color="black"
          height="10"
          class="my-4"
        ></v-progress-linear>
        <v-data-table
          :headers="headers.devices"
          :items="displayedData.devices"
          :items-per-page="loadAllActive.devices ? Math.min(totalItems.devices, 10000) : pageSize"
          :server-items-length="totalItems.devices"
          :page.sync="currentPage.devices"
          @click:row="onRowClick('devices', $event)"
          @update:page="onPageUpdate('devices', $event)"
          @update:items-per-page="onPageSizeChange('devices', $event)"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, { text: 'Max (10,000)', value: 10000 }]
          }"
        >
          <template v-slot:item.operations="{ item }">
            {{ item.operations.length }}
          </template>
        </v-data-table>
      </v-tab-item>
      
      <!-- ---------------------- Procedures ---------------------- -->
      <v-tab-item>
        <div class="d-flex flex-row align-center mb-4">
          <v-text-field
            label="Search Procedures"
            v-model="searchQuery.procedures"
            @input="onSearchInput('procedures')"
            @keyup.enter="onSearchSubmit('procedures')"
            filled
            hide-details
            :prepend-inner-icon="isServerSearch['procedures'] ? 'mdi-magnify' : 'mdi-filter-variant'"
            :append-icon="isServerSearch['procedures'] ? 'mdi-arrow-right' : ''"
            @click:append="onSearchSubmit('procedures')"
          ></v-text-field>
          <v-btn
            class="ml-4"
            text
            v-if="!loadAllActive['procedures'] && !loading['procedures']"
            @click="loadAll('procedures')"
            :loading="loading.procedures"
          >
            <v-icon left>mdi-refresh</v-icon>Load All
          </v-btn>
          <v-btn
            class="ml-4"
            text
            v-if="loading['procedures']"
            @click="cancelLoading['procedures'] = true"
          >
            <v-icon left>mdi-cancel</v-icon>Cancel Loading
          </v-btn>
        </div>
        <v-progress-linear
          v-if="loading['procedures']"
          :value="loadProgress['procedures']"
          color="black"
          height="10"
          class="my-4"
        ></v-progress-linear>
        <v-data-table
          :headers="headers.procedures"
          :items="displayedData.procedures"
          :items-per-page="loadAllActive.procedures ? Math.min(totalItems.procedures, 10000) : pageSize"
          :server-items-length="totalItems.procedures"
          :page.sync="currentPage.procedures"
          @click:row="onRowClick('procedures', $event)"
          @update:page="onPageUpdate('procedures', $event)"
          @update:items-per-page="onPageSizeChange('procedures', $event)"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, { text: 'Max (10,000)', value: 10000 }]
          }"
        ></v-data-table>
      </v-tab-item>
        
      <!-- ---------------------- Analyze ---------------------- -->
      <v-tab-item class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-file-input
              label="Select file"
              v-model="selectedFile"
              :disabled="uploading"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="!selectedFile || uploading"
              @click="uploadFile"
            >
              Upload File
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="uploading">
            <v-progress-linear
              :value="uploadProgress"
              color="blue"
              height="10"
              striped
            ></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="imageUrls.length > 0">
            <h2>Extracted Pages</h2>
            <v-row>
              <v-col
                v-for="(url, index) in imageUrls"
                :key="index"
                cols="4"
              >
                <v-img :src="url" class="my-6" aspect-ratio="1" style="margin: 0 50px"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
        
    <!-- ---------------------- Document Dialog ---------------------- -->
    <v-dialog v-model="dialogVisible.documents" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Record Details</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr v-for="(value, key) in selectedRecord.documents" :key="key">
                <td><strong>{{ key }}</strong></td>
                <td>{{ value | formatValue }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="confirm('documents')"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon @click="deny('documents')"><v-icon>mdi-close</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogVisible.documents = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ---------------------- Device Dialog ---------------------- -->
    <v-dialog v-model="dialogVisible.devices" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Device Details</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr v-for="(value, key) in selectedRecord.devices" :key="key">
                <td><strong>{{ key }}</strong></td>
                <td>
                  <template v-if="isIdField(key, 'devices')">
                    <span v-for="(id, index) in parseIds(value)" :key="index">
                      <a href="#" @click.prevent="onIdClick(id, key, 'devices')">{{ id }}</a><span v-if="index < parseIds(value).length - 1">, </span>
                    </span>
                  </template>
                  <template v-else>
                    {{ value | formatValue }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="confirm('devices')"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon @click="deny('devices')"><v-icon>mdi-close</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogVisible.devices = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ---------------------- Operation Dialog ---------------------- -->
    <v-dialog v-model="dialogVisible.operations" max-width="80%">
      <v-card>
        <v-card-title>
          <span class="headline">Operation Details</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <!-- ------------ Table ------------ -->
            <v-simple-table class="flex-1" style="background: #f5f5f5;">
              <tbody>
                <tr v-for="(value, key) in selectedRecord.operations" :key="key">
                  <td><strong>{{ key }}</strong></td>
                  <td>
                    <template v-if="isIdField(key, 'operations')">
                      <span v-for="(id, index) in parseIds(value)" :key="index">
                        <a href="#" @click.prevent="onIdClick(id, key, 'operations')">{{ id }}</a><span v-if="index < parseIds(value).length - 1">, </span>
                      </span>
                    </template>
                    <template v-else>
                      {{ value | formatValue }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- ------------ Image Preview ------------ -->
            <div class="flex-2 image-preview">
              <img :src="imageUrl" width="100%">
              <!-- <pre>
                {{ selectedRecord.operations }}<br>
              </pre>
              <pre>
                Index: {{ selectedRecord.operations?.index }}<br>
                Page: {{ selectedRecord.operations?.page }}<br>
              </pre> -->
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="confirm('operations')"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon @click="deny('operations')"><v-icon>mdi-close</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogVisible.operations = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ---------------------- Procedure Dialog ---------------------- -->
    <v-dialog v-model="dialogVisible.procedures" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Procedure Details</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr v-for="(value, key) in selectedRecord.procedures" :key="key">
                <td><strong>{{ key }}</strong></td>
                <td>
                  <template v-if="isIdField(key, 'procedures')">
                    <span v-for="(id, index) in parseIds(value)" :key="index">
                      <a href="#" @click.prevent="onIdClick(id, key, 'procedures')">{{ id }}</a><span v-if="index < parseIds(value).length - 1">, </span>
                    </span>
                  </template>
                  <template v-else>
                    {{ value | formatValue }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="confirm('procedures')"><v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon @click="deny('procedures')"><v-icon>mdi-close</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogVisible.procedures = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import { storage } from "@/firebase/init";
  import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
  import { getFirestore, collection, getDocs, doc, onSnapshot, getDoc, query, limit, startAfter, orderBy, documentId, where, getCountFromServer, updateDoc, writeBatch } from "firebase/firestore";
  import SwitchOrdersBackground from "@/components/Operations/SwitchOrdersBackground.vue";
  export default {
    name: "OperationsSwitchOrders",
    components: {
      SwitchOrdersBackground,
    },
    data() {
      return {
        tab: 0,
        pageSize: 10,
        imageUrl: null,
        loading: {
          documents: false,
          devices: false,
          operations: false,
          procedures: false,
        },
        searchQuery: {
          documents: "",
          devices: "",
          operations: "",
          procedures: "",
        },
        currentPage: {
          documents: 1,
          devices: 1,
          operations: 1,
          procedures: 1,
        },
        loadAllActive: {
          documents: false,
          devices: false,
          operations: false,
          procedures: false,
        },
        originalTotalItems: {
          documents: 60015, // Known total number of records
          devices: 80142,
          operations: 255290,
          procedures: 14665,
        },
        headers: {
          documents: [
            { text: "Filename", value: "name" },
            { text: "Path", value: "path" },
            { text: "Pages", value: "pages" },
            { text: "Status", value: "status" },
            // { text: "Verified", value: "verified" },
          ],
          devices: [
            { text: "Id", value: "id" },
            { text: "Device Name", value: "device" },
            { text: "Operations", value: "operations" },
            // { text: "Verified", value: "verified" },
          ],
          operations: [
            { text: "Id", value: "id" },
            { text: "Device", value: "device" },
            { text: "Operation", value: "operation" },
            { text: "Confidence", value: "confidence" },
            // { text: "Verified", value: "verified" },
          ],
          procedures: [
            { text: "Id", value: "id" },
            { text: "Name", value: "name" },
            { text: "Device Count", value: "length" },
            // { text: "Verified", value: "verified" },
          ],
        },
        selectedRecord: {
          documents: null,
          devices: null,
          operations: null,
          procedures: null,
        },
        isServerSearch: {
          documents: false,
          devices: false,
          operations: false,
          procedures: false,
        },
        dialogVisible: {
          documents: false,
          devices: false,
          operations: false,
          procedures: false,
        },
        rawData: {
          documents: [],
          devices: [],
          operations: [],
          procedures: [],
        },
        displayedData: {
          documents: [],
          devices: [],
          operations: [],
          procedures: [],
        },
        //
        lastVisible: {
          documents: {},
          devices: {},
          operations: {},
          procedures: {},
        },
        pageData: {
          documents: {},
          devices: {},
          operations: {},
          procedures: {},
        },
        //
        totalItems: {
          documents: 60015, // Known total number of records
          devices: 80142,
          operations: 255290,
          procedures: 14665, // Example value
        },
        loadProgress: {
          documents: 0,
          devices: 0,
          operations: 0,
          procedures: 0,
        },
        cancelLoading: {
          documents: false,
          devices: false,
          operations: false,
          procedures: false,
        },
        fieldMapping: {
          documents: {
            filename: 'name',
            filepath: 'path',
            pages: 'pages',
            status: 'status',
            verified: 'verified',
          },
          devices: {
            id: 'id',
            device: 'device',
            operations: 'operations',
            verified: 'verified',
          },
          operations: {
            id: 'id',
            device_id: 'device_id',
            operation: 'operation',
            confidence: 'confidence',
            verified: 'verified',
          },
          procedures: {
            id: 'id',
            name: 'name',
            length: 'length',
            verified: 'verified',
            devices: 'devices',
          },
        },
        //
        uploading: false,
        uploadProgress: 0,
        selectedFile: null,
        imageUrls: [],
        docId: null,
        
        clearVerifiedProgress: 0,
        isClearingVerified: false,
      }
    },
     methods: {
      async confirm(dataType) {
        if (!this.selectedRecord[dataType]) return;
  
        const firestore = getFirestore();
        const docRef = doc(firestore, `op_switchorders_${dataType}`, this.selectedRecord[dataType].id);
  
        try {
          await updateDoc(docRef, {
            verified: true,
          });
          this.selectedRecord[dataType].verified = true; // Update locally
          this.updateDisplayedData(dataType);
          this.dialogVisible[dataType] = false;
          console.log(`Document with ID ${this.selectedRecord[dataType].id} marked as verified`);
        } catch (error) {
          console.error(`Error updating document: ${error}`);
        }
      },
      async deny(dataType) {
         if (!this.selectedRecord[dataType]) return;
  
        const firestore = getFirestore();
         const docRef = doc(firestore, `op_switchorders_${dataType}`, this.selectedRecord[dataType].id);
  
         try {
          await updateDoc(docRef, {
             verified: false,
           });
           this.selectedRecord[dataType].verified = false; // Update locally
          this.updateDisplayedData(dataType);
          this.dialogVisible[dataType] = false;
           console.log(`Document with ID ${this.selectedRecord[dataType].id} marked as unverified`);
         } catch (error) {
          console.error(`Error updating document: ${error}`);
         }
      },
      async getSwtichOrderDocuments() {
        console.log("Getting switch order documents");
        this.loading.documents = true;
        // Get data from "op_switchorders_documents" Firebase collection
        const firestore = getFirestore();
        const collectionRef = collection(firestore, "op_switchorders_documents");
        try {
          const querySnapshot = await getDocs(collectionRef);
          const documents = [];
          querySnapshot.forEach((doc) => {
            documents.push({
              id: doc.id,
              ...doc.data(),
            })
            // console.log('Loading: ', doc.id);
          });
          this.documents = documents;
          this.loading.documents = false;
        } catch (error) {
          console.error("Error getting documents: ", error);
          this.loading.documents = false;
        }
      },
      async getSwtichOrderDevices() {
        console.log("Getting switch order devices");
        this.loading.devices = true
        // Get data from "op_switchorders_devices" Firebase collection
        const firestore = getFirestore();
        const collectionRef = collection(firestore, "op_switchorders_devices");
        try {
          const querySnapshot = await getDocs(collectionRef);
          const devices = [];
          querySnapshot.forEach((doc) => {
            devices.push({
              id: doc.id,
              ...doc.data(),
            })
          });
          this.devices = devices;
          this.loading.devices = false;
        } catch (error) {
          console.error("Error getting devices: ", error);
          this.loading.devices = false;
        }
      },
      async getSwtichOrderOperations() {
        console.log("Getting switch order operations");
        this.loading.operations = true
        // Get data from "op_switchorders_operations" Firebase collection
        const firestore = getFirestore();
        const collectionRef = collection(firestore, "op_switchorders_operations");
        try {
          const querySnapshot = await getDocs(collectionRef);
          const operations = [];
          querySnapshot.forEach((doc) => {
            operation = doc.data();
            operation.id = doc.id;
            operations.push(operation);
            console.log(operation)
          });
          this.operations = operations;
          this.loading.operations = false;
        } catch (error) {
          console.error("Error getting operations: ", error);
          this.loading.operations = false;
        }
      },
      async getSwitchOrderProcedures() {
        console.log("Getting switch order procedures");
        this.loading.procedures = true
        // Get data from "op_switchorders_procedures" Firebase collection
        const firestore = getFirestore();
        const collectionRef = collection(firestore, "op_switchorders_procedures");
        try {
          const querySnapshot = await getDocs(collectionRef);
          const procedures = [];
          querySnapshot.forEach((doc) => {
            procedures.push({
              id: doc.id,
              ...doc.data(),
            })
          });
          this.procedures = procedures;
          this.loading.procedures = false;
        } catch (error) {
          console.error("Error getting procedures: ", error);
          this.loading.procedures = false;
        }
      },
      extractWorkId(filename) {
        const match = filename.match(/Job (\d+)/);
        return match ? match[1] : null;
      },
      async uploadFile() {
        const file = this.selectedFile;
        if (!file) return;
  
        // Extract the work ID from the file name
        const workId = this.extractWorkId(file.name);
        if (!workId) {
          console.error("Invalid file name. Could not extract work ID.");
          return;
        }
  
        // Specify the directory inside the bucket
        const directory = `switchorders/${workId}`;
        const fileName = "original.pdf";
        const storageRef = ref(storage, `${directory}/${fileName}`);
  
        // Create the file upload task
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        // Update progress bar
        this.uploading = true;
        this.uploadProgress = 0;
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.uploadProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error("Error uploading file: ", error);
            this.uploading = false;
          },
          async () => {
            this.uploading = false;
            console.log("File uploaded successfully");
  
            // Trigger the HTTP Firebase Function after upload
            try {
              const response = await fetch(
                "https://northamerica-northeast1-hydro-ottawa-ai.cloudfunctions.net/processSwitchOrder",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ workId }),
                },
              );
              const result = await response.json();
              this.imageUrls = result.imageUrls;
              this.docId = result.docId; // Set docId to start listening for changes
              console.log("Processing triggered successfully");
            } catch (error) {
              console.error("Error calling function: ", error);
            }
          },
        );
      },
      setupFirestoreListener(docId) {
        const firestore = getFirestore();
        const docRef = doc(firestore, "switch_orders", docId);
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            this.imageUrls = data.imageUrls;
            console.log("Document data:", data);
          } else {
            console.log("No such document!");
          }
        });
      },
      async fetchBatch(collectionName, dataType, itemsPerPage, page = 1) {
        // Ensure itemsPerPage does not exceed Firestore's maximum limit
        itemsPerPage = Math.min(itemsPerPage, 10000);
  
        const firestore = getFirestore();
        const collectionRef = collection(firestore, collectionName);
  
        let queryRef;
        const isServerSearch = this.isServerSearch[dataType];
        const queryText = this.searchQuery[dataType].trim();
        let field = null;
        let term = null;
  
        if (isServerSearch && queryText.includes(":")) {
          [field, term] = queryText.split(":").map((s) => s.trim());
  
          // Map the field name (case-insensitive)
          const mappedField = this.fieldMapping[dataType][field.toLowerCase()];
          if (!mappedField) {
            console.error(`Field "${field}" is not valid for data type "${dataType}".`);
            return;
          }
          field = mappedField;
  
          console.log(`Performing server-side search on field "${field}" for term "${term}"`);
        } else if (isServerSearch) {
          // For general search, specify a default field
          field = 'name'; // Adjust as needed
          term = queryText;
        }
  
        if (isServerSearch && field && term) {
          try {
            if (page > 1) {
              const lastVisibleDoc = this.lastVisible[dataType][page - 1];
              if (lastVisibleDoc) {
                queryRef = query(
                  collectionRef,
                  where(field, '>=', term),
                  where(field, '<=', term + '\uf8ff'),
                  orderBy(field),
                  startAfter(lastVisibleDoc),
                  limit(itemsPerPage)
                );
              } else {
                console.warn(`No lastVisible document found for page ${page - 1}.`);
                return;
              }
            } else {
              queryRef = query(
                collectionRef,
                where(field, '>=', term),
                where(field, '<=', term + '\uf8ff'),
                orderBy(field),
                limit(itemsPerPage)
              );
            }
          } catch (error) {
            console.error(`Error constructing query for ${dataType}:`, error);
            return;
          }
        } else {
          // No server-side search; regular pagination
          if (page > 1) {
            const lastVisibleDoc = this.lastVisible[dataType][page - 1];
            if (lastVisibleDoc) {
              queryRef = query(
                collectionRef,
                orderBy(documentId()),
                startAfter(lastVisibleDoc),
                limit(itemsPerPage)
              );
            } else {
              console.warn(`No lastVisible document found for page ${page - 1}.`);
              return;
            }
          } else {
            queryRef = query(
              collectionRef,
              orderBy(documentId()),
              limit(itemsPerPage)
            );
          }
        }
  
        try {
          const querySnapshot = await getDocs(queryRef);
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
  
          // Store the last visible document
          const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
          if (lastDoc) {
            this.lastVisible[dataType][page] = lastDoc;
          } else {
            this.lastVisible[dataType][page] = null;
          }
  
          // Store the data for this page
          if (!this.pageData[dataType]) {
            this.pageData[dataType] = {};
          }
          this.pageData[dataType][page] = data;
  
          // Update displayed data
          this.updateDisplayedData(dataType);
  
          // Update total items
          if (isServerSearch) {
            // Get total count of items matching the query
            await this.fetchTotalItems(collectionName, dataType, field, term);
          }
        } catch (error) {
          console.error(`Error fetching ${dataType}:`, error);
        }
      },
      updateDisplayedData(dataType) {
      let data;
  
      if (this.loadAllActive[dataType]) {
        // All data is loaded
        data = Object.values(this.pageData[dataType]).flat();
        this.totalItems[dataType] = data.length;
      } else {
        // Use data from the current page
        data = this.pageData[dataType][this.currentPage[dataType]] || [];
      }
  
      let filteredData = data;
  
      if (!this.isServerSearch[dataType]) {
        const query = this.searchQuery[dataType].trim().toLowerCase();
        if (query) {
          const fieldsToSearch = this.headers[dataType].map(header => header.value);
  
          filteredData = data.filter(item => {
            return fieldsToSearch.some(field => {
              const fieldValue = item[field];
              if (fieldValue !== undefined && fieldValue !== null) {
                return fieldValue.toString().toLowerCase().includes(query);
              }
              return false;
            });
          });
  
          // Update total items to the number of filtered items
          this.totalItems[dataType] = filteredData.length;
  
          // Reset current page if it exceeds total pages
          const totalPages = Math.ceil(this.totalItems[dataType] / this.pageSize);
          if (this.currentPage[dataType] > totalPages) {
            this.currentPage[dataType] = 1;
          }
        } else {
          // When query is empty, reset total items to original count
          this.totalItems[dataType] = this.originalTotalItems[dataType];
        }
      }
  
      // If a 'verified' field exists in the table then set it to unknown if undefined
      filteredData = filteredData.map(item => {
        if(this.headers[dataType].find(header => header.value === 'verified') && item.verified === undefined){
            item.verified = "unknown";
        }
  
        if (dataType === 'devices' && item.completed_operations) {
            item.completed_operations_count = item.completed_operations.length;
        }
  
        return item;
      });
  
      this.displayedData[dataType] = filteredData;
    },
      async fetchTotalItems(collectionName, dataType, field = null, term = null) {
        const firestore = getFirestore();
        const collectionRef = collection(firestore, collectionName);
  
        let queryRef = field && term
          ? query(
              collectionRef,
              where(field, ">=", term),
              where(field, "<=", term + "\uf8ff")
            )
          : collectionRef;
  
        try {
          const snapshot = await getCountFromServer(queryRef);
          this.totalItems[dataType] = snapshot.data().count;
        } catch (error) {
          console.error(`Error fetching total count for ${dataType}:`, error);
        }
      },
      async onPageUpdate(dataType, page) {
        this.currentPage[dataType] = page;
  
        if (this.loadAllActive[dataType]) {
          // Update displayed data for the new page
          this.updateDisplayedData(dataType);
        } else {
          // Check if data for this page is already loaded
          if (!this.pageData[dataType] || !this.pageData[dataType][page]) {
            await this.fetchBatch(`op_switchorders_${dataType}`, dataType, this.pageSize, page);
          } else {
            this.updateDisplayedData(dataType);
          }
        }
      },
      async onPageSizeChange(dataType, newPageSize) {
        if (newPageSize <= 0 || newPageSize > 10000) {
          newPageSize = 10000; // Cap at 10,000
        }
        this.pageSize = newPageSize;
        this.currentPage[dataType] = 1;
        this.loadAllActive[dataType] = false;
        this.pageData[dataType] = {};
        await this.fetchBatch(`op_switchorders_${dataType}`, dataType, this.pageSize, 1);
        this.updateDisplayedData(dataType);
      },
      onSearchChange(dataType) {
        this.currentPage[dataType] = 1; // Reset to the first page
        this.updateDisplayedData(dataType);
      },
      
      async loadAll(dataType) {
        console.log(`Loading all data for ${dataType}`);
        this.loading[dataType] = true;
        this.loadAllActive[dataType] = true;
        this.pageData[dataType] = {}; // Clear existing data
        this.currentPage[dataType] = 1; // Reset current page
        this.loadProgress[dataType] = 0; // Initialize progress
        this.cancelLoading[dataType] = false; // Reset cancel flag
  
        const itemsPerPage = 1000; // Adjust as needed
        let page = 1;
        let totalItemsLoaded = 0;
  
        while (true) {
          if (this.cancelLoading[dataType]) {
            console.log(`Loading of ${dataType} canceled by user.`);
            break;
          }
  
          await this.fetchBatch(`op_switchorders_${dataType}`, dataType, itemsPerPage, page);
          const itemsLoaded = this.pageData[dataType][page]?.length || 0;
          totalItemsLoaded += itemsLoaded;
          this.loadProgress[dataType] = (totalItemsLoaded / this.totalItems[dataType]) * 100;
  
          if (itemsLoaded < itemsPerPage || totalItemsLoaded >= this.totalItems[dataType]) {
            break;
          }
  
          page++;
        }
  
        // After exiting the loop (either due to completion or cancellation), update displayed data
        this.displayedData[dataType] = Object.values(this.pageData[dataType]).flat();
  
        // Update total items to reflect the number of items loaded
        this.totalItems[dataType] = this.displayedData[dataType].length;
  
  // Adjust pagination if needed
  if (this.cancelLoading[dataType]) {
    this.loadAllActive[dataType] = false; // Revert to normal pagination
    this.pageSize = 10; // Reset to default page size or keep current
  }
  
  // Reset loading states
  this.loading[dataType] = false;
  this.cancelLoading[dataType] = false;
  },
  isQueryTerm(dataType) {
  const query = this.searchQuery[dataType].trim();
  return query.includes(":");
  },
  onSearchInput(dataType) {
  const query = this.searchQuery[dataType].trim();
  if (query === '') {
    // Reset to initial state
    this.isServerSearch[dataType] = false;
    this.currentPage[dataType] = 1;
    this.pageData[dataType] = {};
    this.lastVisible[dataType] = {};
    this.loadAllActive[dataType] = false;
    this.fetchTotalItems(`op_switchorders_${dataType}`, dataType);
    this.fetchBatch(`op_switchorders_${dataType}`, dataType, this.pageSize, 1);
  } else if (this.isQueryTerm(dataType)) {
    // Switch to server-side search mode
    this.isServerSearch[dataType] = true;
  } else {
    // Client-side filtering mode
    this.isServerSearch[dataType] = false;
    this.updateDisplayedData(dataType);
  }
  },
  async onSearchSubmit(dataType) {
  if (this.isServerSearch[dataType]) {
    console.log(`Executing server-side search for ${dataType}`);
    this.currentPage[dataType] = 1;
    this.pageData[dataType] = {}; // Clear existing data
    this.lastVisible[dataType] = {}; // Reset pagination tracking
    this.loadAllActive[dataType] = false;
    await this.fetchBatch(`op_switchorders_${dataType}`, dataType, this.pageSize, 1);
  }
  },
  async onRowClick(dataType, item) {
    this.selectedRecord[dataType] = item;
    console.log('Selected Record:', dataType, this.selectedRecord[dataType], this.selectedRecord[dataType].index, this.selectedRecord[dataType].page); // Add this line
    this.imageUrl = null;
    if (dataType === 'operations') {
      // Get the image from Google Cloud Storage
      const storageRef = ref(storage, `operations/switchorders/concept/resized/${item.index}-${item.page}.jpg`);
      try {
        const url = await getDownloadURL(storageRef);
        this.imageUrl = url;
      } catch (error) {
        console.error('Error fetching image from Google Cloud Storage:', error);
      }
    }
    // Open the dialog
    this.dialogVisible[dataType] = true;
  },
  isIdField(key, dataType) {
    const idFields = {
      devices: ['operations', 'completed_operations'],
      operations: ['id', 'device_id', 'procedure_id'],
      procedures: ['operations', 'completed_operations', 'device_ids', 'devices'], // Added 'devices' here
      documents: ['id'],
    };
    return idFields[dataType] && idFields[dataType].includes(key);
  },
  parseIds(value) {
    if (Array.isArray(value)) {
      return value;
    } else if (typeof value === 'string') {
      return value.split(',').map(id => id.trim());
    } else if (value && typeof value === 'object') {
      return Object.values(value);
    } else {
      return [value];
    }
  },
  onIdClick(id, fieldKey, currentDataType) {
    let targetDataType = '';
    if (currentDataType === 'devices' && ['operations', 'completed_operations'].includes(fieldKey)) {
      targetDataType = 'operations';
    } else if (currentDataType === 'operations' && ['device_id'].includes(fieldKey)) {
      targetDataType = 'devices';
    } else if (currentDataType === 'operations' && ['procedure_id'].includes(fieldKey)) {
      targetDataType = 'procedures';
    } else if (currentDataType === 'procedures' && ['device_ids'].includes(fieldKey)) { // Only 'device_ids' is checked here
      targetDataType = 'devices';
    } else {
      // Handle other cases or default
      console.warn(`Cannot determine target data type for field ${fieldKey} in ${currentDataType}`);
      return;
    }
    // Close current dialog
    this.dialogVisible[currentDataType] = false;
    // Fetch and open new dialog
    this.fetchRecordById(id, targetDataType);
  },
  async fetchRecordById(id, dataType) {
    const firestore = getFirestore();
    const docRef = doc(firestore, `op_switchorders_${dataType}`, id);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const record = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        // Set the selected record and open the dialog
        this.selectedRecord[dataType] = record;
        this.dialogVisible[dataType] = true;
      } else {
        console.error(`No such document with ID ${id} in collection ${dataType}`);
      }
    } catch (error) {
      console.error(`Error fetching document with ID ${id} in collection ${dataType}:`, error);
    }
  },
  },
    async mounted() {
      // Initial load for all data types
      await Promise.all([
      this.fetchBatch("op_switchorders_documents", "documents", this.pageSize, 1),
      this.fetchBatch("op_switchorders_devices", "devices", this.pageSize, 1),
      this.fetchBatch("op_switchorders_operations", "operations", this.pageSize, 1),
      this.fetchBatch("op_switchorders_procedures", "procedures", this.pageSize, 1),
      ]);
      // Save the initial top position of the preview
      const previewContainer = this.$refs.previewContainer;
      if (previewContainer) {
      const rect = previewContainer.getBoundingClientRect();
      this.previewTop = window.scrollY + rect.top;
      }
  // Attach scroll listener
  // window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
  // Cleanup scroll listener
  window.removeEventListener('scroll', this.handleScroll);
  },
    filters: {
      formatValue(value) {
        if (typeof value === 'boolean') {
          return value ? 'Yes' : 'No';
        } else if (Array.isArray(value)) {
          return value.join(', '); // Format arrays as comma-separated strings
        } else if (value === "unknown"){
          return "unknown";
        }
        return value;
      },
      truncate(value) {
        const length = 50;
        return value.length > length ? value.substring(0, length) + '...' : value;
      },
    },
    watch: {
      docId(newDocId) {
        if (newDocId) this.setupFirestoreListener(newDocId);
      },
    },
  }
</script>

<style>
  .max-width-250 {
    max-width: 250px;
  }
</style>  