<template>
  <v-container>
    <h1 class="mb-4">Call Analysis</h1>
    <div class="d-flex flex-column flex-md-row">
      <!-- ------------ Sidebar (Desktop) ------------ -->
      <div class="sidebar the-thin-grey-border flex-column d-none d-md-flex flex-1">
        <v-tabs v-model="tab" vertical>
          <v-tab>Dashboard</v-tab>
          <v-tab>Trends</v-tab>
          <v-tab>Interactive</v-tab>
          <v-tab>Explorer</v-tab>
          <v-tab>Concept</v-tab>
          <v-tab>Background</v-tab>
        </v-tabs>
      </div>
      <!-- ------------ Top Bar (Mobile) ------------ -->
      <div class="d-flex the-thin-grey-border flex-row d-md-none">
        <v-tabs v-model="tab">
          <v-tab>Dashboard</v-tab>
          <v-tab>Trends</v-tab>
          <v-tab>Interactive</v-tab>
          <v-tab>Explorer</v-tab>
          <v-tab>Concept</v-tab>
          <v-tab>Background</v-tab>
        </v-tabs>
      </div>
      <!-- ------------ Main Content ------------ -->
      <div class="d-flex the-thin-grey-border flex-column flex-3 ml-md-4 mt-md-0 mt-4">
        <v-tabs-items v-model="tab">
          <!-- ------ Dashboard ------ -->
          <v-tab-item>
            <v-card-text>
              <h1 class="mt-2 mb-6 black--text">Dashboard</h1>
              <v-sheet color="yellow lighten-5 pa-4 mb-6" rounded>
                <div class="">
                  <v-icon color="yellow darken-3">mdi-lightbulb-on-outline</v-icon>
                  <div class="font-weight-bold">Insight of the Day</div>
                  <div class="text-caption font-italic">March 16, 2025</div>
                  <p class="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                </div>
              </v-sheet>
              
              <h2 class="mb-6">Call Categories</h2>
              <p>
                Reason customers are calling (the initial driver of the call should be categories (and should be bucketed in sub categories for deeper insight - ex: Billing: Billing Delay - also additional max 3 topics should be itemized separately)
              </p>
              <v-chart class="chart" :option="dashboardCategoriesChart" />
              <v-chart class="chart" :option="dashboardSubCategoriesChart" />

              <h2 class="mb-6 mt-12">Caller Sentiment</h2>
              <p>as an indicator of customer satisfaction.</p>
              <ul class="text-left">
                <li>tracked over time to identify trends and pinpoint areas for improvement.</li>
                <li>
                  analyze sentiment in conjunction with call categories to understand which issues are causing the most frustration to customers
                  most frustration to customers
                </li>
              </ul>

              <h2 class="mb-6 mt-12">Customer Satisfaction</h2>
              <p>derive what CSAT would be on the pillars we measure - if we were to survey all</p>
              <ul class="text-left">
                <li>
                  Trained model that learns the correlation between post call satisfaction score and actual call -
                  then could apply to all calls?
                </li>
              </ul>
              <v-chart class="chart" :option="dashboardSentimentChart" />

              <h2 class="mb-6 mt-12">Opportunities for Improvement</h2>
              <p>Top 5 number of and types of opportunities identified</p>

              <h2 class="mb-6 mt-12">Call Length</h2>
              <p>> 15 minutes</p>

              <h2 class="mb-6 mt-12">Escalations</h2>
              <p>number of calls a customer is asking for an escalation, to speak to a supervisor</p>

              <h2 class="mb-6 mt-12">Customer Effort Score</h2>
              <ul class="text-left">
                <li>Repeated explanations or questions - The customer having to explain their problem multiple times or
                  ask
                  the same question repeatedly</li>
                <li>Call length - its not always a direct indicator, but unusually long calls might suggest a complex
                  issue
                  requiring customer effort</li>
                <li>Long hold and silences - extended periods of silence or being put on hold, implying the agent is
                  struggling to find a solution.</li>
                <li>Transfers or escalations(see above) - the call being transferred between agents or escalated to a
                  supervisor, or customer is asking to escalate</li>
                <li>Technical issues - hello, I cant hear you, or a very short duration call etc - identify how many
                  from
                  caller how many from agent,</li>
                <li>Negative sentiment - high level of frustration or dissatisfaction expressed by the customer</li>
                <li>agent apologies - frequent apologies from the agent can indicate a problem with the service or
                  process</li>
              </ul>
              <h3 class="mb-6 mt-12">Calculating the score</h3>
              <ul class="text-left">
                <li>Can a pre-trained model language model have a good grasp of language nuances to be able to recognize
                  effort-related patterns?</li>
                <li>Develop rules around each item - for example - repeated explanations or questions, what would
                  constitute
                  an effort , more than 2X? Increase the effort score for each time above that..</li>
                <li>Using ML correlate Survey results with call , to understand pattern?</li>
              </ul>

              <h2 class="mb-6 mt-12">Repeat Callers</h2>
              <div class="text-caption">We do not have this metadata yet.</div>
              <p># of callers with X calls over Y period. Helps guide understanding customer
                behavior/knowledge and agent knowledge/process gaps - we make improvements, see repeat calls go down
                which
                in turn improve the experience, bring csat up and decrease inquiries. However, this would be of greater
                use if we were able to do review over a longer period of time. Once we get the granular data in tableau
                -
                especially the meta data , we can have Pham look at it in a broader perspective .</p>

              <h2 class="mb-6 mt-12">Quality</h2>
              <p>
                today we have standard/traditional mechanisms to evaluate quality (customer service) - however while the
                existing processes provide a foundation, we recognize the need for more robust and scalable analysis to
                drive meaningful change that will provide comprehensive analysis and reporting of customer interactions.
              </p>
              <ul class="text-left">
                <li>Scalability (audit large volume of interactions, not limited by human capacity)</li>
                <li>Consistency (objective, data-driven assessments, reducing the potential for individual biases.)</li>
                <li>Efficiency (Automation of routine tasks, allowing us to focus on complex analysis, systematic
                  improvements, and targeted agent coaching)</li>
                <li>Insights and trends (reveal patterns and trends in customer feedback that may not be visible through
                  manual assessment, enabling proactive problem solving and continuous improvement)</li>
              </ul>
            </v-card-text>
          </v-tab-item>
          <!-- ------ Trends ------ -->
          <v-tab-item>
            <v-card-text>
              <h1 class="mt-2 mb-6 black--text">Trends</h1>
              <v-sheet color="yellow lighten-5 pa-4 mb-6" rounded>
                <div class="">
                  <v-icon color="yellow darken-3">mdi-lightbulb-on-outline</v-icon>
                  <div class="font-weight-bold">Insight of the Day</div>
                  <div class="text-caption font-italic">March 16, 2025</div>
                  <p class="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                </div>
              </v-sheet>
              
              <h2 class="mb-6">Call Categories</h2>
              <p>
                Reason customers are calling (the initial driver of the call should be categories (and should be bucketed in sub categories for deeper insight - ex: Billing: Billing Delay - also additional max 3 topics should be itemized separately)
              </p>
              <v-chart class="chart" :option="trendsCategoriesChart" />
              <v-chart class="chart" :option="trendsSubCategoriesChart" />

              <h2 class="mb-6 mt-12">Caller Sentiment</h2>
              <p>as an indicator of customer satisfaction.</p>
              <ul class="text-left">
                <li>tracked over time to identify trends and pinpoint areas for improvement.</li>
                <li>
                  analyze sentiment in conjunction with call categories to understand which issues are causing the most frustration to customers
                  most frustration to customers
                </li>
              </ul>

              <h2 class="mb-6 mt-12">Customer Satisfaction</h2>
              <p>derive what CSAT would be on the pillars we measure - if we were to survey all</p>
              <ul class="text-left">
                <li>
                  Trained model that learns the correlation between post call satisfaction score and actual call -
                  then could apply to all calls?
                </li>
              </ul>

              <h2 class="mb-6 mt-12">Opportunities for Improvement</h2>
              <p>Top 5 number of and types of opportunities identified</p>

              <h2 class="mb-6 mt-12">Call Length</h2>
              <p>> 15 minutes</p>

              <h2 class="mb-6 mt-12">Escalations</h2>
              <p>number of calls a customer is asking for an escalation, to speak to a supervisor</p>

              <h2 class="mb-6 mt-12">Customer Effort Score</h2>
              <ul class="text-left">
                <li>Repeated explanations or questions - The customer having to explain their problem multiple times or
                  ask
                  the same question repeatedly</li>
                <li>Call length - its not always a direct indicator, but unusually long calls might suggest a complex
                  issue
                  requiring customer effort</li>
                <li>Long hold and silences - extended periods of silence or being put on hold, implying the agent is
                  struggling to find a solution.</li>
                <li>Transfers or escalations(see above) - the call being transferred between agents or escalated to a
                  supervisor, or customer is asking to escalate</li>
                <li>Technical issues - hello, I cant hear you, or a very short duration call etc - identify how many
                  from
                  caller how many from agent,</li>
                <li>Negative sentiment - high level of frustration or dissatisfaction expressed by the customer</li>
                <li>agent apologies - frequent apologies from the agent can indicate a problem with the service or
                  process</li>
              </ul>

              <h2 class="mb-6 mt-12">Calculating the score</h2>
              <ul class="text-left">
                <li>Can a pre-trained model language model have a good grasp of language nuances to be able to recognize
                  effort-related patterns?</li>
                <li>Develop rules around each item - for example - repeated explanations or questions, what would
                  constitute
                  an effort , more than 2X? Increase the effort score for each time above that..</li>
                <li>Using ML correlate Survey results with call , to understand pattern?</li>
              </ul>

              <h2 class="mb-6 mt-12">Repeat Callers</h2>
              <p># of callers with X calls over Y period. Helps guide understanding customer
                behavior/knowledge and agent knowledge/process gaps - we make improvements, see repeat calls go down
                which
                in turn improve the experience, bring csat up and decrease inquiries. However, this would be of greater
                use if we were able to do review over a longer period of time. Once we get the granular data in tableau
                -
                especially the meta data , we can have Pham look at it in a broader perspective .</p>

              <h2 class="mb-6 mt-12">Quality</h2>
              <p>
                today we have standard/traditional mechanisms to evaluate quality (customer service) - however while the
                existing processes provide a foundation, we recognize the need for more robust and scalable analysis to
                drive meaningful change that will provide comprehensive analysis and reporting of customer interactions.
              </p>
              <ul class="text-left">
                <li>Scalability (audit large volume of interactions, not limited by human capacity)</li>
                <li>Consistency (objective, data-driven assessments, reducing the potential for individual biases.)</li>
                <li>Efficiency (Automation of routine tasks, allowing us to focus on complex analysis, systematic
                  improvements, and targeted agent coaching)</li>
                <li>Insights and trends (reveal patterns and trends in customer feedback that may not be visible through
                  manual assessment, enabling proactive problem solving and continuous improvement)</li>
              </ul>
            </v-card-text>
          </v-tab-item>
          <!-- ------ Interactive ------ -->
          <v-tab-item>
            <v-card-text class="text-left">
              <h1 class="mt-2 mb-6 black--text">Interactive</h1>
              <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
                Interact with the last week of call data
              </v-sheet>
            </v-card-text>
          </v-tab-item>
          <!-- ------ Explorer ------ -->
          <v-tab-item>
            <v-card-text class="text-left">
              <h1 class="mt-2 mb-6 black--text">Explorer</h1>
              <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
                Directly access the last 7 days of call recordings  
              </v-sheet>
              <ul>
                <li>Browse 7 days of calls</li>
                <li>Search any call ID</li>
                <li>Filter by agent, category, sentiment, etc.</li>
                <li>View call details</li>
              </ul>
            </v-card-text>
          </v-tab-item>
          <!-- ------ Concept ------ -->
          <v-tab-item>
            <v-card-text>
              <h1 class="mt-2 mb-6 black--text">Original Concept</h1>
              <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
                Our original proof of concept
              </v-sheet>
              <CallAnalysisConcept />
            </v-card-text>
          </v-tab-item>
          <!-- ------ Background ------ -->
          <v-tab-item>
            <v-card-text>
              <h1 class="mt-2 mb-6 black--text">Background</h1>
              <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
                Our journey to build the Call Analysis tool
              </v-sheet>
              <CallAnalysisBackground />
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <!--

              
          <v-tab-item class="pa-6">
            
            <v-card-text>
              <v-text-field v-model="interactiveQuery" filled rounded @keyup.enter="sendInteractiveQuery"
                append-outer-icon="mdi-send" @click:append-outer="sendInteractiveQuery" :disabled="interactiveLoading">
                <template v-slot:prependInner>
                  <v-icon>mdi-check</v-icon>
                </template>
                <template v-slot:append>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item v-for="(prompt, index) in interactivePrompts" :key="'prompt' + index"
                        @click="interactiveQuery = prompt.title">
                        <v-list-item-subtitle class="ellipsis text-left">{{ prompt.title | truncate }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-text-field>
              <v-divider></v-divider>
              <div class="message-history">
                <div v-if="interactiveLoading" class="text-center pa-4">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  <span class="ml-2">{{ timer }} seconds (processing will take a few minute)</span>
                </div>
                <v-slide-x-transition group class="d-flex flex-column-reverse">
                  <div v-for="(message, index) in interactiveHistory" :key="index" class="message d-flex"
                    :class="message.type == 'user' ? 'justify-end' : 'justify-start'">
                    <div v-if="message.type === 'user'" class="user-message pa-4 ma-2">
                      {{ message.content }}
                    </div>
                    <div v-else>
                      <div>
                        <div class="system-message pa-6 ma-2" v-html="message.content"></div>
                        <div v-if="message.citations && message.citations.length" class="citations ml-2">
                          <div v-for="(citation, citationIndex) in message.citations"
                            :key="'citation-' + index + '-' + citationIndex" class="text-caption">
                            [{{ citationIndex + 1 }}] <a :href="citation.uri" target="_blank">{{ citation.uri }}</a> ({{
                              citation.startIndex }} - {{ citation.endIndex }})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-slide-x-transition>
              </div>
              <div class="d-flex justify-end">
                <v-btn v-if="interactiveHistory.length" @click="startConversation()" :disabled="interactiveLoading" text>
                  <v-icon left>mdi-refresh</v-icon> Reset Conversation
                </v-btn>
              </div>
            </v-card-text>
          </v-tab-item>

          <v-tab-item class="pa-6">
            <h1 class="mb-6 black--text">Explorer</h1>
            <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
              Browse and search through the last 100,000 calls
            </v-sheet>
            <v-card-text>
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" rounded
                filled></v-text-field>
              <v-data-table :headers="explorerHeaders" :items="filteredExplorerCategories" class="elevation-1"
                @click:row="openCallDialog">
                <template v-slot:progress>
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>

          <v-tab-item class="pa-6">
            <h1 class="mb-6 black--text">Background</h1>
            <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
              How we built the call analysis
            </v-sheet>
            <CallAnalysisBackground />
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="callDialog" width="800">
      <v-card class="text-left">
        <v-card-title>
          {{ selectedItem?.id }}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="callDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-sheet color="#f1f3f4" class="d-flex align-center">
          <audio ref="transcriptPlayer"
            :src="selectedItem?.audio"
            controls>
          </audio>
        </v-sheet>
        <v-card-text class="mt-6">
          <v-row>
            <v-col cols="12" md="6">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Summary</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.reasoning?.gemini?.Summary?.detailed
                    }}</v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Transcription (Google)</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.transcription?.chirp }}</v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Transcription (OpenAI)</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>{{ selectedItem?.transcription?.whisper }}</v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="6">
              <v-expansion-panels>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Categories</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Primary Category:</strong>
                    {{ selectedItem?.reasoning?.gemini?.Categorization?.primary }}<br>
                    <strong>Secondary Category:</strong>
                    {{ selectedItem?.reasoning?.gemini?.Categorization?.secondary }}<br>
                    <strong>Categorization Logic:</strong>
                    {{ selectedItem?.reasoning?.gemini?.Categorization?.logic }}<br>
                    <hr>
                    <strong>Primary Issue:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.primary_issue }}<br>
                    <strong>Secondary Issues:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.secondary_issues | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Call Information</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Date:</strong>
                    {{ selectedItem?.date }}<br>
                    <strong>Quality:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Call Information']?.call_quality
                    }}<br>
                    <strong>Customer:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Call Information']?.caller_name
                    }}<br>
                    <strong>Address:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Call Information']?.caller_address
                    }}<br>
                    <strong>Agent:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Call Information']?.agent_name }}<br>
                    <strong>Language:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Language']?.language }}<br>
                    <strong>Curses:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Language']?.curses }}<br>
                    <strong>Hold Occurrences:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.hold_occurrences
                    }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Issue Identification</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Customer Concerns:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.issue_identification?.customer_concerns }}<br>
                    <strong>Specific Concerns:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.issue_identification?.specific_issues
                      |
                      joinArray }}<br>
                    <strong>Agreed Solution:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.agreed_solution }}<br>
                    <strong>Confirmation Number:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.confirmation_number }}<br>
                    <strong>Confirmation Number:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.confirmation_number }}<br>
                    <strong>Recommendations:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.recommendations |
                      joinArray
                    }}<br>
                    <strong>Follow-up:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.follow_up }}<br>
                    <strong>Next Steps:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Topic Categorization']?.resolution?.next_steps | joinArray
                    }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">MyAccount</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Expired Password?:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.expired_password }}<br>
                    <strong>Issues:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.myaccount_issues | joinArray }}<br>
                    <strong>Tried MyAccount:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['MyAccount']?.tried_myaccount }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Sentiment</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Agent:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.agent }}<br>
                    <strong>Customer:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.customer }}<br>
                    <strong>Overall:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.overall }}<br>
                    <strong>Score:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.score }}<br>
                    <strong>Shifts?:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Sentiment']?.shifts | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Policy Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Knowledge:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['AI Training']?.valuable_information | joinArray }}<br>
                    <strong>Policies:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['AI Training']?.policies | joinArray }}<br>
                    <strong>Procedures:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['AI Training']?.procedures | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Triage Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Customer:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Triage']?.first_customer_statement }}<br>
                    <strong>Human Response:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Triage']?.first_agent_response }}<br>
                    <strong>AI Response:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Triage']?.ai_response }}<br>
                    <strong>Scenario:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.training?.scenario }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Key Actions</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Programs & Services Discussed:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.programs_and_services_discussed | joinArray
                    }}<br>
                    <strong>Agent Guidance:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.agent_guidance | joinArray }}<br>
                    <strong>Documentation:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.documentation_needs }}<br>
                    <strong>Payment:</strong>
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.payment_information }}<br>
                    <strong>Modifications:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.cancellation_modification_actions | joinArray }}<br>
                    <strong>Payment Plan Changed:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.payment_plan_setup }}<br>
                    <strong>System References:</strong> {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.system_references | joinArray }}<br>
                    <strong>Verification Process:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.verification_process | joinArray }}<br>
                    <strong>Agent Follow-up Confirmation:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.agent_followup_confirmation }}<br>
                    <strong>Customer Requests:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Key Actions']?.customer_requests | joinArray }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Agent Performance and Training</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>Best Practices:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.best_practices | joinArray }}<br>
                    <strong>Improvement Areas:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.improvement_areas | joinArray }}<br>
                    <strong>Best Practices:</strong> 
                    {{ selectedItem?.reasoning?.gemini?.['Agent Performance and Training']?.training?.scenario }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-h6">Improvements</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong>System Improvements:</strong> 
                    {{ improvements(selectedItem?.reasoning?.gemini?.['Improvements']?.system_improvements) }}<br>
                    <strong>Service Improvements:</strong> 
                    {{ improvements(selectedItem?.reasoning?.gemini?.['Improvements']?.service_improvements) }}<br>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->

  </v-container>
</template>

<script>
const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]

import { getFirestore, collection, getDoc, getDocs, query, limit, startAfter, addDoc, setDoc, doc } from 'firebase/firestore'
import localCategories from '@/assets/categories_v2.json';
import CallAnalysisBackground from '@/components/CustomerService/CallAnalysisBackground.vue';
import CallAnalysisConcept from '@/components/CustomerService/CallAnalysisConcept.vue';
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, LineChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components'
import VChart from 'vue-echarts'

// Register ECharts components
use([
  CanvasRenderer,
  PieChart,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
])

// Chart Examples: https://echarts.apache.org/examples/en/index.html#chart-type-line

export default ({
  name: 'CustomerServiceCallAnalysis',
  props: ['user'],
  components: {
    CallAnalysisBackground,
    CallAnalysisConcept,
    VChart
  },
  data: () => ({
    // ------ General ------
    tab: 0,

    // ------ Dashboard ------
    width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,

    // Add chart options
    dashboardCategoriesChart: {
      title: {
        text: 'Daily Call Categories',
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      
      series: [
        {
          name: 'Call Categories',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 235, name: 'Billing & Payments', itemStyle: { color: '#5470c6' } },
            { value: 274, name: 'Rates & Plans', itemStyle: { color: '#91cc75' } },
            { value: 310, name: 'Account Management', itemStyle: { color: '#fac858' } },
            { value: 335, name: 'Service & Connections', itemStyle: { color: '#ee6666' } },
            { value: 400, name: 'Outages & Emergencies', itemStyle: { color: '#73c0de' } },
            { value: 123, name: 'Program & Service Support', itemStyle: { color: '#3ba272' } },
            { value: 45, name: 'Other Inquiries', itemStyle: { color: '#aeaeae' } }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    },
    dashboardSubCategoriesChart: {
      title: {
        text: 'Daily Call Sub Categories',
        left: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      
      series: [
        {
          name: 'Call Sub Categories',
          type: 'pie',
          radius: '50%',
          data: [
            // Billing & Payments
            { value: 6991, name: 'Account Balance & Bill Inquiries', itemStyle: { color: '#5470c6' } },
            { value: 3135, name: 'High Bill / Usage Inquiries', itemStyle: { color: '#5470c6' } },
            { value: 12850, name: 'Other Billing Disputes', itemStyle: { color: '#5470c6' } },
            { value: 984, name: 'Budget Billing & Equal Payment Plans', itemStyle: { color: '#5470c6' } },
            { value: 4418, name: 'Payment Methods & Options', itemStyle: { color: '#5470c6' } },
            { value: 8224, name: 'Payment Issues', itemStyle: { color: '#5470c6' } },
            { value: 3, name: 'Late Payments & Collections', itemStyle: { color: '#5470c6' } },
            { value: 7249, name: 'Payment Arrangements & Extensions', itemStyle: { color: '#5470c6' } },
            { value: 7900, name: 'Payment Confirmation', itemStyle: { color: '#5470c6' } },
            { value: 1610, name: 'Pre-Authorized Payments', itemStyle: { color: '#5470c6' } },
            { value: 1213, name: 'Financial Assistance & Subsidies', itemStyle: { color: '#5470c6' } },
            { value: 0, name: 'Security Deposits', itemStyle: { color: '#5470c6' } },
            // Rates & Plans
            { value: 1265, name: 'Rate plan options and comparisons', itemStyle: { color: '#91cc75' } },
            { value: 87, name: 'Understanding Energy Usage & Consumption', itemStyle: { color: '#91cc75' } },
            { value: 30, name: 'Energy Efficiency Programs & Rebates', itemStyle: { color: '#91cc75' } },
            // Account Management
            { value: 1, name: 'Updating Account Information', itemStyle: { color: '#fac858' } },
            { value: 11553, name: 'New Account Setup', itemStyle: { color: '#fac858' } },
            { value: 9190, name: 'Account Closure & Termination', itemStyle: { color: '#fac858' } },
            { value: 14516, name: 'Moves - Service Start/Stop', itemStyle: { color: '#fac858' } },
            { value: 252, name: 'Merging Multiple Accounts', itemStyle: { color: '#fac858' } },
            { value: 2095, name: 'Deceased Account Management', itemStyle: { color: '#fac858' } },
            { value: 3999, name: 'Landlord & Tenants', itemStyle: { color: '#fac858' } },
            { value: 5001, name: 'MyAccount Technical Support', itemStyle: { color: '#fac858' } },
            // Service & Connections
            { value: 12643, name: "Service Requests", itemStyle: { color: '#ee6666' } },
            { value: 1447, name: "Vegetation Management", itemStyle: { color: '#ee6666' } },
            // Outages & Emergencies
            { value: 2239, name: "Power Outages", itemStyle: { color: '#73c0de' } },
            { value: 112, name: "Power Quality & Reliability", itemStyle: { color: '#73c0de' } },
            { value: 3, name: "Safety Concerns During Outages", itemStyle: { color: '#73c0de' } },
            { value: 2, name: "Generator Inquiries & Safety", itemStyle: { color: '#73c0de' } },
            { value: 3, name: "Troubleshooting Electrical Issues", itemStyle: { color: '#73c0de' } },
            { value: 29, name: "Battery Loan", itemStyle: { color: '#73c0de' } },
            { value: 18, name: "Outage Communications", itemStyle: { color: '#73c0de' } },
            // Program & Service Support
            { value: 362, name: "Distrubuted Energy Resources (DER)", itemStyle: { color: '#973ba2' } },
            // Other Inquiries
            { value: 0, name: "Privacy Policy & Data Protection", itemStyle: { color: '#3ba272' } },
            { value: 115, name: "Feedback & Suggestions", itemStyle: { color: '#3ba272' } },
            { value: 269, name: "Scam / Fraud", itemStyle: { color: '#3ba272' } },
            // Other
            { value: 1713, name: "Other", itemStyle: { color: '#aeaeae' } },
          ],
          label: {
            show: false
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    },

    // ------ Trends ------
    trendsCategoriesChart: {
      title: {
        text: 'Trends in Call Categories',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: ['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04', '2024-01-05']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Category A',
          data: [120, 200, 150, 80, 70],
          type: 'line',
          stack: 'total'
        },
        {
          name: 'Category B',
          data: [60, 80, 90, 50, 40],
          type: 'line',
          stack: 'total'
        },
        {
          name: 'Category C',
          data: [30, 50, 70, 40, 30],
          type: 'line',
          stack: 'total'
        }
      ]
    },
    trendsSubCategoriesChart: {
      title: {
        text: 'Trends in Call Sub Categories',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: ['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04', '2024-01-05']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Category A',
          data: [120, 200, 150, 80, 70],
          type: 'bar',
          stack: 'total'
        },
        {
          name: 'Category B',
          data: [60, 80, 90, 50, 40],
          type: 'bar',
          stack: 'total'
        },
        {
          name: 'Category C',
          data: [30, 50, 70, 40, 30],
          type: 'bar',
          stack: 'total'
        }
      ]
    },

    // ------ Interactive ------
    insteractiveSessionId: Date.now().toString(36) + Math.random().toString(36), // Generate a simple unique session ID
    interactiveQuery: '',
    interactiveLoading: false,
    interactivePrompts: [
      { title: 'Analyze the call quality', class: 'primary' },
      { title: 'How can Hydro Ottawa improve', class: 'primary' },
      { title: 'what are customers calling about the most, list one thing', class: 'primary' },
      { title: 'can you breakdown the billing issues into 5 sub categories', class: 'primary' },
      { title: 'were there any calls that stood out as being a non-standard category', class: 'primary' },
      { title: 'tell me more about the power quality issues', class: 'primary' },
      { title: 'draft an email explaining that to a customer that has complained about this', class: 'primary' },
    ],
    interactiveLogDocId: null,
    interactiveHistory: [],
    interactiveTimer: 0,
    interactiveTimerInterval: null,
    
    // ------ Explorer ------
    explorerCategories: localCategories,
    // filteredExplorerCategories: [], // this is a computed property
    explorerSearch: '',
    explorerHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Date', value: 'date' },
      { text: 'Category', value: 'primary' },
      { text: 'Subcategory', value: 'secondary' },
    ],
    selectedItem: null,

  }),
  methods: {

    initialize() {
      // this.load(true); // Initial load only, fetching the first 500 records
      // this.filteredItems = this.items;
      // this.explorerLogDocId = null; // Reset explorer log document ID for a new search
      // this.loadCategories();
      // this.loadJourneys();
      // this.fetchData()
    },

    // ====================== Interactive Methods ======================
    async startConversation() {
      this.interactiveHistoryhistory = [];
      this.interactiveSessionId = Date.now().toString(36) + Math.random().toString(36); // Generate a new session ID for a new conversation
      this.interactiveLogDocId = null; // Reset assistant log document ID for a new conversation
    },
    async sendInteractiveQuery() {
      if (!this.interactiveQuery) return;
      this.interactiveLoading = true;
      this.startTimer(this.interactiveTimer, this.interactiveTimerInterval);
      this.interactiveHistory.push({ type: 'user', content: this.userQuery });
  
      // Log the query before sending the request
      await this.logQuery('interactive');
  
      try {
        // const endpoint = 'http://localhost:5000/hydro-ottawa-ai/northamerica-northeast1/geminicalls';
        const endpoint = 'https://northamerica-northeast1-hydro-ottawa-ai.cloudfunctions.net/geminicalls';
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userMessage: this.userQuery, sessionId: this.sessionId })
        });
        const data = await response.json();
        if (data.success) {
          const replacedContent = data.html.replace(/F\d{12}V\d{1}U\d{7}/g, (match) => {
            // console.log('Match:', match);
            return `<a href="#" class="call-link" data-id="${match}">${match}</a>`;
          });
          this.interactiveHistory.push({ type: 'model', content: replacedContent, citations: data.citations });
        } else {
          this.interactiveHistory.push({ type: 'model', content: 'Error: ' + data.response });
        }
      } catch (error) {
        this.interactiveHistory.push({ type: 'model', content: 'Error: ' + error.message });
      } finally {
        this.interactiveLoading = false;
        this.stopTimer(this.interactiveTimer, this.interactiveTimerInterval);
        // Log the query again after receiving the response or handling an error
        await this.logQuery('interactive');
      }
      this.interactiveQuery = '';
    },
    async logQuery(type) {
      const db = getFirestore();
      const logsRef = collection(db, 'queries');
      try {
        if (type === 'explorer') {
          const logDoc = {
            user: this.user.email,
            type,
            query: this.search || '', // Ensure query is not undefined
          };
          await addDoc(logsRef, logDoc);
        } else if (type === 'assistant') {
          const logDoc = {
            user: this.user.email,
            type,
            history: this.history.map(item => item.content), // Flattening the history array to just contain the content
          };
          if (this.assistantLogDocId) {
            // console.log('assistant update', this.assistantLogDocId, logDoc);
            // Update existing log document
            const logDocRef = doc(logsRef, this.assistantLogDocId);
            await setDoc(logDocRef, logDoc, { merge: true }); // Use merge to only update fields
          } else {
            // console.log('assistant create', logDoc);
            // Create new log document
            const logDocRef = await addDoc(logsRef, logDoc);
            this.assistantLogDocId = logDocRef.id;
          }
        }
      } catch (error) {
        console.error('Error logging query:', error);
      }
    },
    startTimer(timer, timerInterval) {
      timer = 0;
      if (timerInterval) clearInterval(timerInterval);
      timerInterval = setInterval(() => {
        timer++;
      }, 1000);
    },
    stopTimer(timer, timerInterval) {
      if (timerInterval) clearInterval(timerInterval);
    },
    async openCallDialog(item) {
      console.log(item.id)
      if (!item) return false
      // Fetch the selected item from the Firebase collection
      const db = getFirestore();
      const callsRef = collection(db, 'cs_customer_calls_v2');
      const callDocRef = doc(callsRef, item.id);
      const callDoc = await getDoc(callDocRef);
      this.selectedItem = callDoc.data();
      // We have to fetch the audio file from our firestore
      // TODO
      this.selectedItem.audio = ''
      this.callDialog = true;
    },

  },
  filters: {
    truncate(string) {
      let value = 80;
      return string?.length > value ? string.slice(0, value) + "..." : string;
    },
    joinArray(value) {
      return Array.isArray(value) ? value.join(', ') : 'N/A';
    },
  },
  watch: {
    user() {
      this.startConversation();
      this.initialize();
    },
    $route(to, from) {
      this.startConversation();
      this.initialize();
    },
    transcriptionDialog() {
      const player = this.$refs.transcriptPlayer;
      if (player) {
        player.pause();
        player.currentTime = 0; // Reset the time
      }
    }
  },
  mounted() {
    // Load initial 500 records
    this.initialize()
    this.$nextTick(() => {
      document.addEventListener('click', (event) => {
        if (event.target.classList.contains('call-link')) {
          event.preventDefault();
          const callId = event.target.getAttribute('data-id');
          this.openCallDialog({ id: callId });
        }
      });
    });
  },
  computed: {
    filteredExplorerCategories() {
      // Only filter if search has 3+ characters
      if (!this.explorerSearch || this.explorerSearch.length < 3) {
        return this.explorerCategories;
      }

      // Match on id, date, primary, secondary
      return this.explorerCategories.filter(item => {
        const searchTerm = this.explorerSearch.toLowerCase();
        return (item.id && item.id.toLowerCase().includes(searchTerm)) ||
          (item.date && item.date.toLowerCase().includes(searchTerm)) ||
          (item.primary && item.primary.toLowerCase().includes(searchTerm)) ||
          (item.secondary && item.secondary.toLowerCase().includes(searchTerm));
      });
    }
  }
})
</script>

<style lang="scss" scoped>
.message {
  .system-message {
    max-width: 80%;
    background: #f0f0f0;
    color: black;
    border-radius: 10px;
    text-align: left !important;
  }

  .user-message {
    max-width: 80%;
    background: #4caf50;
    color: white;
    border-radius: 10px;
  }
}

.citations {
  font-size: 0.875rem;
  color: #757575;
}

.sidebar {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}
.the-thin-grey-border {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}
.chart {
  height: 400px;
  width: 100%;
}
</style>