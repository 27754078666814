<template>
  <v-container>
    <h1>Content Writer</h1>
    <div class="d-flex flex-row">
      <v-card class="d-flex flex-column flex-1 mr-4">
        <v-list>
          <v-list-item two-line link>
            <v-list-item-content>
             <v-list-item-title>Contractors & Developers</v-list-item-title>
             <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line link>
            <v-list-item-content>
             <v-list-item-title>Unit Metering</v-list-item-title>
             <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line link>
            <v-list-item-content>
             <v-list-item-title>Distributed Energy Resources</v-list-item-title>
             <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line link>
            <v-list-item-content>
             <v-list-item-title>Types of DERs</v-list-item-title>
             <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line link>
            <v-list-item-content>
             <v-list-item-title>How to Pay Your Bill</v-list-item-title>
             <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="d-flex flex-column flex-3">
        <v-card-title>
          Content
          <v-spacer></v-spacer>
          <v-btn-toggle>
            <v-btn>Edit</v-btn>
            <v-btn>Delete</v-btn>
          </v-btn-toggle>

        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'CommunicationsContentWriter',
    data () {
      return {
        
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  /*

----------------------  
Page Title: Contractors & Developers
Page Content: The Developer, Builder, Contractor, Renovator and Landscaper's Guide to Hydro Ottawa

Whether you are renovating, installing a pool, building (small, large, or adding-on), landscaping, interlocking a driveway, or seeking City approval for severances or minor variances, there is information you need to be aware of and regulations that need to be followed.

Residential and Commercial Services
In order to provide a detailed design and cost estimates for your projects, Hydro Ottawa requires the information below. All drawings should be provided electronically in a Hydro Ottawa approved file format and adhere to drawing features procedure ECS0031 (Digital Base Mapping Requirements for Electrical Servicing).

Residential Subdivision - 3 or more homes 
Project name;
Unit servicing detail;
Number of units to be serviced;
A plan of the subdivision, to scale, showing the building(s) offset in relation to existing and proposed property lines, other buildings, streets, street entrances, driveways, side-yards, and the location of other services including gas, telephone, water, cablevision, waste water (sewage and storm, including catch basins);
Grading plan;
Landscaping plan;
Road cross-sections;
Expectation of third or fourth party trenching (Note: fourth party trenching is not available for private roads);
Proposed construction schedule, and phasing of the development;
Developer detail; and
Load Summary form for all townhouse and multi-unit buildings.
Residential Infill - 2 or less homes
A grading and site plan showing the building(s) in relation to existing and proposed property lines, other buildings, streets and driveways, and the location of other services including gas, telephone, water and cablevision;
Civic addresses;
Customer billing information as specified in the Account Information Form – New Construction (Appendix D, Conditions of Service);
A legal reference plan by a land surveyor;
A municipal servicing plan showing the location of water and sewer services;
A layout showing the number of units and the size of electrical services required;
A completed Load Summary Form (Appendix A, Conditions of Service) may be required;
Requested energizing date;
Proposed building offsets from property lines; and
A Load Summary Form.
Commercial Projects
Electrical Single Line Diagrams showing proposed metering points;
Civic addresses;
Customer billing information as specified in Appendix D (Conditions of Service)
Account Information Form – New Construction
New Energy Resource Facility Account
Site plan in AutoCAD and PDF formats showing the location of the service entrance, indoor electrical vault, and pad mounted equipment on site;
A vault room layout if an indoor electrical vault is required; and
A Load Summary Form (Appendix A, Conditions of Service). 
Fault Level Information
If you require fault level information from Hydro Ottawa, send your request to FaultLevel@hydroottawa.com, along with the following information:

Civic address;
Building name on the site and/or vault number (if available);
Reason for the request (i.e. service upgrade, new service); and
A completed Fault Level Information Consent form.
Once your request is received, Hydro Ottawa will provide you with the following information as a minimum: 

Point of supply nomenclature (i.e. vault or transformer);
Voltage;
Minimum and maximum fault level;
LLL MVA & X/R
LG MVA & X/R
Transformer information (voltage on the primary and secondary service, kVA, number of transformers, and impedance).
You can expect to receive a response within four weeks of submission.

Accuracy of Information: Hydro Ottawa endeavours to maintain the accuracy of this information. It makes no representation or warranty, however, as to the accuracy of this information and cannot be held responsible for errors.

Power Consumptions Requests
If you require power consumption history, please send your request to customerservice@hydroottawa.com, along with the following information:

Address;
Account number;
Premise ID;
Name;
Meter number;
Time period;
Power information you’re looking for (i.e. kWh, kVa, VAR, power factor); and
Requested interval size (i.e. 5 minutes, 60 minutes, 24 hours).
If the account owner is not the party requesting the information, a written permission from the account owner must accompany the request. 

A Special Billing Service Charge of $140.00 (billed in 15 minute increments with a minimum charge of one hour) is applied when customers request historical billing information. This does not include duplicate invoices and arrears certificates, which have a specific rate.
 


Account Information
Use our online Account Information Form – New Construction (Appendix D, Conditions of Service) to open or update an existing account. It’s important to give us at least 10 business days advance notice. This will ensure that your account information is accurate and that you have electrical service when you need it.

Before you get started, you will need to have one of the following pieces of government issued identification on hand:

Driver’s Licence Number and Jurisdiction;
Passport number;
Service Ontario Photo ID Number.
You may also need:

The 20-digit account number of an existing Hydro Ottawa service:
To find your account number, look at the top of your most recent bill or log into MyAccount;
Your HST, Business Identification, and Incorporation Number.
Locating the 20-digit account number on your bill:
A sample of a Hydro Ottawa bill pointing out the needed information for an OESP application.

Locating the 20-digit account number on MyAccount:
A sample of a Hydro Ottawa bill pointing out the needed information for an OESP application.

Clearances
Clearances are established to ensure public and worker safety, prevent a power outage and/or allow space for operating, maintaining, and replacing the electricity distribution infrastructure.

Seeking Severance, Minor Variance, or Permit
Learn more about where Hydro Ottawa electrical distribution infrastructure or electrical servicing exists before seeking City approvals.

Electrical Servicing
Submit your request for a new electrical connection or an upgrade to an existing service online.

Miscellaneous
Learn more about property, re-instatement, demolition, and locating underground electrical distribution infrastructure.

In order to make the design and construction process as quick and problem free as possible, Hydro Ottawa provides its standard construction drawings free of charge to developers, contractors and consultants. Click here to view the available design specifications and drawings Commercial Design Specifications.

  */
</style>