<template>
  <v-tabs vertical>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-lightbulb
      </v-icon>
      Idea
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-file-document-outline
      </v-icon>
      Examples
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-chart-box
      </v-icon>
      Analysis
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-check-decagram
      </v-icon>
      Recommendations
    </v-tab>
    <v-tab disabled class="justify-start">
      <v-icon left>
        mdi-flag-checkered
      </v-icon>
      Conclusion
    </v-tab>
  
    <!-- ------------ Idea ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <div class="text-h6 text-center ml-12">Can we extract useful insights from our historical switch order documents?
            </div>
          </v-sheet>
          <p>
            Hydro Ottawa maintains a large repository of switch order documents. Over 15 years, we have accumulated 40,655 job folders containing 377,759 documents.<br>
            These documents contain PC14 and PC17A forms which contain useful information about the devices and procedures associated with a job.
            By extracting this information we can gain metrics on a device level.
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ---------------------- Examples ---------------------- -->
    <v-tab-item class="pa-6">
      <div class="d-flex flex-row">
        <v-card class="d-flex flex-column" max-width="300" tile>
          <v-list rounded>
            <v-subheader>Challenging Switch Orders</v-subheader>
            <v-list-item-group v-model="selectedExampleIndex" color="primary">
              <v-list-item v-for="(item, i) in examples" :key="i">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <div class="d-flex flex-1 preview-container justify-center" ref="previewContainer" v-if="selectedExample">
          <div class="preview" :class="isSticky ? 'sticky' : ''">
            <img v-if="selectedExample" :src="selectedExample.url" class="pt-6">
          </div>
        </div>
      </div>
    </v-tab-item>

    <!-- ---------------------- Summary ---------------------- -->
    <v-tab-item class="pa-6">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Updated Dataset
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            
            40,655 > 50,687 job folders<br>
            377,759 > 88,547 files<br>
            60,015 > x PDF files<br>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Documents
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column flex-md-row">
              <div class="flex-1 ma-0 ma-md-6">
                <img style="width: 100%" src="@/assets/op_switchorders_documents.svg">
              </div>
              <div class="flex-1">
                <h3>Baseline Dataset</h3>
                <p>The dataset consists of a collection of folders. Each folder contains documents related to a specific job. The documents we are interested in are only .pdf files, so we filter out all other files.</p>
                  <div class="text-left ml-12">
                  40,655 Job Folders<br>
                  377,759 Documents<br>
                  60,015 PDF Files<br>
                </div>
                <hr class="my-4">
                <h3>Labeled Dataset</h3>
                <p>A PDF contains a collection of individual paper forms relating to a job. However, some documents are copies or drafts so there can be multiple packages per job. To filter these we rely upon the filename looking for keywords such as "completed", "copy", "draft", etc. Sometimes the filenames can be ambiguous (such as F123456789.pdf which was scanned but not renamed) so we process these as well to be filtered using other methods.</p>
                <div class="text-left ml-12">
                  15,004 Confirmed Job Files<br>
                  510 Ambiguous Job Files<br>
                  44,501 Incomplete Job Files<br>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Pages
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column flex-md-row">
              <div class="flex-1 ma-0 ma-md-6">
                <img style="width: 100%" src="@/assets/op_switchorders_classification.svg">
              </div>
              <div class="flex-1">
                <h3>Seperate Pages</h3>
                <p>Each document is split into separate pages and processed separately based on the original confidence of the document to be valid.</p>
                <div class="text-left ml-12">
                  176,153 Confirmed Pages<br>
                  3,411 Ambiguous Pages<br>
                </div>
                <hr class="my-4">
                <h3>Classification (confirmed)</h3>
                <p>A model was trained to classify each form. Switch orders can either be a PC17A form or the back page of a PC14 form so we can filter out all others. The model was trained on multiple variations of each form to ensure accuracy.</p>
                <div class="text-left ml-12">
                  <span class="font-italic">Confirmed</span><br>
                  3,924 PC14<br>
                  18.286 PC17A (HOL branded)<br>
                  32,365 PC17A (unbranded)<br>
                  6 PC17A (Hydro One)<br>
                  1,953 PC17A (IHSA)<br>
                  <br><span class="font-italic">Ambiguous</span><br>
                  111 PC14<br>
                  407 PC17A (HOL branded)<br>
                  463 PC17A (unbranded)<br>
                  0 PC17A (Hydro One)<br>
                  25 PC17A (IHSA)<br>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Forms
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column flex-md-row">
              <div class="flex-1 ma-0 ma-md-6">
                <img style="width: 100%" src="@/assets/op_switchorders_filter.svg">
              </div>
              <div class="flex-1">
                <h3>Merge</h3>
                <p>Forms must be validated based on its content using advanced models. This allows us to merge ambiguous forms as they can be individually verified.</p>
                <div class="text-left ml-12">
                  4,035 PC14<br>
                  18,693 PC17A (HOL branded)<br>
                  32,828 PC17A (unbranded)<br>
                  6 PC17A (Hydro One)<br>
                  1,978 PC17A (IHSA)<br>
                </div>
                <hr class="my-4">
                <h3>Filter</h3>
                <p>A form inside a confirmed package does not mean it should be included. Some forms exist in job packages as copies, drafts, incomplete, or otherwise invalidated and should be filtered.</p>
                <div class="text-left ml-12">
                  4,020 PC14<br>
                  <div class="ml-6">Complete: 2,629</div>
                  <div class="ml-12">Test: 1,746</div>
                  <div class="ml-12">Restore: 31</div>
                  <div class="ml-12">Both: 852</div>
                  <div class="ml-6">Incomplete: 1,180</div>
                  <div class="ml-6">Copy: 186</div>
                  <div class="ml-6">Reversed: 7</div>
                  <div class="ml-6">Duplicates: 18</div>

                  18,001 PC17A (HOL branded)<br>
                  <div class="ml-6">Complete: 14,307</div>
                  <div class="ml-6">Incomplete: 2,833</div>
                  <div class="ml-6">Copy: 754</div>
                  <div class="ml-6">Reversed: 107</div>

                  31,978 PC17A (unbranded)<br>
                  <div class="ml-6">Complete: 22,359</div>
                  <div class="ml-6">Incomplete: 5,949</div>
                  <div class="ml-6">Copy: 3,454</div>
                  <div class="ml-6">Reversed: 210</div>
                  <div class="ml-6">Duplicates: 6</div>

                  1,932 PC17A (IHSA)<br>
                  <div class="ml-6">Complete: 1,729</div>
                  <div class="ml-6">Incomplete: 10</div>
                  <div class="ml-6">Copy: 66</div>
                  <div class="ml-6">Reversed: 13</div>
                  <div class="ml-6">Duplicates: 114</div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Operations
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column flex-md-row">
              <div class="flex-1 ma-0 ma-md-6">
                <img style="width: 100%" src="@/assets/op_switchorders_devices.png">
              </div>
              <div class="flex-1">
                <h3>Operations</h3>
                <p>From each completed document type we extract the line items associated with a completed action.</p>
                <div class="text-left ml-12">
                  255,290 Operations Performed<br>
                </div>
                <hr class="my-4">
                <h3>Devices</h3>
                <p>From all operations, we create a list of unique devices which were operated upon. Some devices will have to be consolidated due to poor legibility or inconsistent names.</p>
                80,142 Unique Devices<br>
                14,665 Unique Procedures<br>
                <hr class="my-4">
                10,254 lines of Python code<br>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-tab-item>
  

    <!-- ------------ Recommendations ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Idea</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <div class="text-h6 text-center ml-12">Can we extract useful insights from our historical switch order documents?
            </div>
          </v-sheet>
          <p>
            Hydro Ottawa maintains a large repository of switch order documents. Over 15 years, we have accumulated 40,655 job folders containing 377,759 documents.<br>
            These documents contain PC14 and PC17A forms which contain useful information about the devices and procedures associated with a job.
            By extracting this information we can gain metrics on a device level.
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Conclusion ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Conclusion</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
  
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
<script>
  import { getStorage, ref, getDownloadURL } from "firebase/storage";

  export default {
    name: 'SwitchOrdersBackground',
    data() {
      return {
        isSticky: false, // Tracks if the preview is sticky
        previewTop: 0,   // Tracks the original position of the preview
        //
        selectedExampleIndex: null,
        selectedExample: null,
        examples: [
          {
              "name": "17-145145",
              "subtitle": "Copy checkmark crossed out",
              "type": "image",
              "filename": "48538-7.jpg",
              "url": ""
          },
          {
              "name": "17-179425",
              "subtitle": "Multiple copy stamps",
              "type": "image",
              "filename": "11578-10.jpg",
              "url": "",
          },
          {
              "name": "HOL14-35867",
              "subtitle": "Faint strike through",
              "type": "image",
              "filename": "12162-8.jpg",
              "url": "",
          },
          {
              "name": "17-167156",
              "subtitle": "Bottom cut off",
              "type": "image",
              "filename": "14800-5.jpg",
              "url": "",
          },
          {
              "name": "17-184699",
              "subtitle": "Sticky note",
              "type": "image",
              "filename": "16488-9.jpg",
              "url": ""
          },
          {
              "name": "026642",
              "subtitle": "Faint copy stamp",
              "type": "image",
              "filename": "17893-12.jpg",
              "url": ""
          },
          {
              "name": "24885-4",
              "subtitle": "Scanning error",
              "type": "image",
              "filename": "24885-4.jpg",
              "url": ""
          },
          {
              "name": "17-147373",
              "subtitle": "Crossed out copy stamp",
              "type": "image",
              "filename": "3282-5.jpg",
              "url": ""
          },
          {
              "name": "HOL17-41",
              "subtitle": "Hydro One",
              "type": "image",
              "filename": "3528-10.jpg",
              "url": ""
          },
          {
              "name": "IHSA 043848",
              "subtitle": "Handwritten copy stamp",
              "type": "image",
              "filename": "36805-5.jpg",
              "url": ""
          },
          {
              "name": "HOL17-20836",
              "subtitle": "Skewed scan",
              "type": "image",
              "filename": "39583-7.jpg",
              "url": ""
          },
          {
              "name": "17-167867",
              "subtitle": "Handwritten copy stamp",
              "type": "image",
              "filename": "41502-4.jpg",
              "url": ""
          },
          {
              "name": "17-187319 (#41521)",
              "subtitle": "Handwritten copy stamp",
              "type": "image",
              "filename": "41521-5.jpg",
              "url": ""
          },
          {
              "name": "17-187319 (#41522)",
              "subtitle": "Faint copy stamp",
              "type": "image",
              "filename": "41522-15.jpg",
              "url": ""
          },
          {
              "name": "17-160456",
              "subtitle": "Partially deleted operations",
              "type": "image",
              "filename": "41870-6.jpg",
              "url": ""
          },
          {
              "name": "025936",
              "subtitle": "Super faint copy stamp",
              "type": "image",
              "filename": "42922-37.jpg",
              "url": ""
          },
          {
              "name": "17-130483",
              "subtitle": "Half a copy stamp",
              "type": "image",
              "filename": "44595-9.jpg",
              "url": ""
          },
          {
              "name": "46959-91",
              "subtitle": "Garbled scan",
              "type": "image",
              "filename": "46959-91.jpg",
              "url": ""
          },
          {
              "name": "17-193811",
              "subtitle": "Duplicate with stamp",
              "type": "image",
              "filename": "5109-9.jpg",
              "url": ""
          },
          {
              "name": "17-193811",
              "subtitle": "Duplicate with stamp",
              "type": "image",
              "filename": "5111-4.jpg",
              "url": ""
          },
          {
              "name": "049035",
              "subtitle": "Faint copy stamp",
              "type": "image",
              "filename": "55243-5.jpg",
              "url": ""
          },
          {
              "name": "59673-3",
              "subtitle": "Fully Handwritten with deletion",
              "type": "image",
              "filename": "59673-3.jpg",
              "url": ""
          },
          {
              "name": "17-143807",
              "subtitle": "'Suspended' written as signature",
              "type": "image",
              "filename": "7598-6.jpg",
              "url": ""
          },
          {
              "name": "IHSA 043189",
              "subtitle": "Fully Handwritten",
              "type": "image",
              "filename": "9242-6.jpg",
              "url": ""
          },
          {
              "name": "IHSA 043190",
              "subtitle": "Fully Handwritten",
              "type": "image",
              "filename": "9242-7.jpg",
              "url": ""
          }
        ],
      }
    },
    created() {
      // Get the urls for all the examples
      this.examples.forEach(example => {
        this.getFirebaseStorageFileUrl('operations/switchorders/concept/exceptions', example.filename).then(url => {
          example.url = url;
        });
      });
    },
    mounted() {
      // Attach scroll listener
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      // Cleanup scroll listener
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      async getFirebaseStorageFileUrl(path, filename) {
        try {
          // Initialize Firebase Storage
          const storage = getStorage();

          // Create a reference to the file
          const fileRef = ref(storage, `${path}/${filename}`);

          // Get the download URL
          const url = await getDownloadURL(fileRef);

          // Use the URL (e.g., set it to an image source)
          // console.log("File URL:", url);
          return url;
        } catch (error) {
          console.error("Error fetching file URL:", error);
        }
      },
      handleScroll() {
        const previewContainer = this.$refs.previewContainer;
        if (!previewContainer) return;

        // Get the container's top position relative to the viewport
        const rect = previewContainer.getBoundingClientRect();

        // Check if the top of the container has reached the top of the viewport
        this.isSticky = rect.top <= 0;

        // Update the top offset if not sticky
        if (!this.isSticky) {
          this.previewTop = window.scrollY + rect.top;
        }
      },
    },
    watch: {
      async selectedExampleIndex(index) {
        this.selectedExample = this.examples[index];
        if (this.selectedExample.url) {
          return;
        }
        await this.getFirebaseStorageFileUrl('operations/switchorders/concept/exceptions', this.selectedExample.filename).then(url => {
          this.selectedExample.url = url;
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
  .preview-container {
    display: flex;
    flex: 1;
    background: #f5f5f5;
    position: relative;
    transition: transform 0.3s ease, position 0.3s ease; /* Smooth transition */
    img {
      height: 700px;
    }
    .sticky {
      position: fixed; /* Fix to the viewport */
      top: 0;
      transition: transform 0.3s ease, position 0.3s ease; /* Smooth transition */
    }
  }
  .image-preview {
    border-left: 1px solid #ccc;
    background-size: cover !important;
    background: url(https://storage.googleapis.com/hydroottawa-audio/switchorders/resized/10001-2.jpg);
  }
</style>