<template>
  <v-container>
    <h2 class="mb-4">Switch Orders – Review</h2>
    
    <!-- Filter switch and counts -->
    <v-switch
      v-model="filterUncategorized"
      label="Show only unclassified documents"
      class="mb-2"
    ></v-switch>
    <p>
      Showing record {{ displayDocuments.length ? currentIndex + 1 : 0 }} of
      {{ displayDocuments.length }} loaded (Total in DB: {{ totalCount }})
    </p>

    <div v-if="currentDocument" class="d-flex flex-column align-center justify-center">
      <!-- Display document id and classification -->
      <p>
        Document ID: <strong>{{ currentDocument.id }}</strong>, 
        Classification:
        <strong>
          {{
            (!currentDocument.classification ||
             currentDocument.classification.trim() === "")
              ? "Unclassified"
              : currentDocument.classification
          }}
        </strong>
      </p>
      <v-alert type="success" v-if="currentDocument.classification === 'GOOD'">
        <strong>GOOD</strong>
      </v-alert>
      <v-alert type="error" v-if="currentDocument.classification === 'BAD'">
        <strong>BAD</strong>
      </v-alert>
      <v-img
        :src="currentImage"
        class="review-image"
        width="75%"
        height="auto"
        contain
      ></v-img>
      <div class="instructions">
        <p>Use your keyboard:</p>
        <p>
          Left Arrow = <strong>BAD</strong> &nbsp;&nbsp;
          Right Arrow = <strong>GOOD</strong> &nbsp;&nbsp;
          Down Arrow = <strong>Skip</strong>
        </p>
      </div>
    </div>
    <div v-else>
      <p>No more images to review.</p>
    </div>

    <!-- Snackbar Component -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>
  
<script>
  import {
    getFirestore,
    collection,
    getDocs,
    setDoc,
    doc,
    query,
    orderBy,
    limit,
    startAfter,
    getCountFromServer,
    where
  } from "firebase/firestore";
  import { getStorage, ref, getDownloadURL } from "firebase/storage";

  export default {
    name: "SwitchOrdersReview",
    data() {
      return {
        documents: [],         // All documents loaded so far
        currentIndex: 0,       // Index into the filtered (display) documents
        preloadQueue: {},      // Preloaded image URLs keyed by document id
        preloadCount: 20,      // Number of images to preload ahead
        batchSize: 50,         // How many documents to load per batch
        lastDoc: null,         // Marker for pagination in Firestore
        loadingBatch: false,   // Flag indicating if a batch is currently loading
        allLoaded: false,      // Flag indicating if all documents have been loaded
        // Snackbar data properties:
        snackbar: false,
        snackbarMessage: '',
        snackbarColor: '',
        // Filter: true = show only unclassified documents, false = show all.
        filterUncategorized: true,
        // Total count from Firestore (always total, not filtered).
        totalCount: 0
      };
    },
    computed: {
      // Filter documents locally. A document is unclassified if the classification field
      // is missing, null, or an empty string.
      displayDocuments() {
        if (this.filterUncategorized) {
          return this.documents.filter(doc => {
            return (
              !doc.classification ||
              (typeof doc.classification === "string" &&
                doc.classification.trim() === "")
            );
          });
        }
        return this.documents;
      },
      currentDocument() {
        return this.displayDocuments[this.currentIndex];
      },
      currentImage() {
        if (!this.currentDocument) return null;
        return this.preloadQueue[this.currentDocument.id];
      }
    },
    watch: {
      // When switching the filter, reset the index.
      filterUncategorized(newVal) {
        this.currentIndex = 0;
        // When switching to unclassified, try to load more if we don't have enough.
        if (newVal && this.displayDocuments.length < 50 && !this.allLoaded) {
          this.loadNextBatch();
        }
      }
    },
    methods: {
      // Load the next batch of documents from Firestore (without filtering).
      async loadNextBatch() {
        if (this.loadingBatch || this.allLoaded) return;
        this.loadingBatch = true;
        try {
          const db = getFirestore();
          let q;
          if (this.lastDoc) {
            q = query(
              collection(db, "op_switchorders_otos"),
              orderBy("job_id"),
              startAfter(this.lastDoc),
              limit(this.batchSize)
            );
          } else {
            q = query(
              collection(db, "op_switchorders_otos"),
              orderBy("job_id"),
              limit(this.batchSize)
            );
          }
          const snapshot = await getDocs(q);
          const newDocs = snapshot.docs.map(docSnap => ({
            id: docSnap.id,
            ...docSnap.data()
          }));
          if (newDocs.length < this.batchSize) {
            this.allLoaded = true;
          }
          if (snapshot.docs.length > 0) {
            this.lastDoc = snapshot.docs[snapshot.docs.length - 1];
          }
          console.debug("Loaded new batch of", newDocs.length, "documents");
          this.documents.push(...newDocs);
          // Preload images for the newly loaded documents.
          this.preloadImages();
        } catch (error) {
          console.error("Error loading batch documents:", error);
        } finally {
          this.loadingBatch = false;
          // If filtering for unclassified and we haven't reached our desired minimum,
          // load another batch (unless all documents have been loaded).
          if (this.filterUncategorized && !this.allLoaded && this.displayDocuments.length < 50) {
            console.debug(
              "Not enough unclassified records loaded (" +
                this.displayDocuments.length +
                "). Loading next batch..."
            );
            await this.loadNextBatch();
          }
        }
      },
      // Preload images for the next preloadCount documents (based on the filtered list).
      async preloadImages() {
        const start = this.currentIndex;
        const end = Math.min(
          this.currentIndex + this.preloadCount,
          this.displayDocuments.length
        );
        const promises = [];
        for (let i = start; i < end; i++) {
          const docData = this.displayDocuments[i];
          if (docData && !this.preloadQueue[docData.id]) {
            promises.push(this.loadImageForDocument(docData));
          }
        }
        try {
          await Promise.all(promises);
        } catch (error) {
          console.error("Error preloading images:", error);
        }
      },
      // Load an image URL from Firebase Storage for a given document.
      async loadImageForDocument(document) {
        try {
          const storage = getStorage();
          const path = `/operations/switchorders/pages/${document.page_id}.jpg`;
          const storageRef = ref(storage, path);
          const url = await getDownloadURL(storageRef);
          this.$set(this.preloadQueue, document.id, url);
        } catch (error) {
          console.error("Error loading image for document", document.id, error);
        }
      },
      // Update the Firestore document with the classification using setDoc.
      async updateClassification(classification) {
        if (!this.currentDocument) return;
        try {
          const db = getFirestore();
          const docRef = doc(db, "op_switchorders_otos", this.currentDocument.id);
          console.debug(
            "Setting classification",
            classification,
            "for document",
            this.currentDocument.id
          );
          await setDoc(docRef, { classification }, { merge: true });
          console.debug("Successfully set classification for document", this.currentDocument.id);
          // Update the local document.
          this.currentDocument.classification = classification;
        } catch (error) {
          console.error("Error updating classification:", error);
          this.showSnackbar("Error updating document", "error");
        }
      },
      // Load the total count of documents from Firestore (unfiltered).
      async loadTotalCount() {
        try {
          const db = getFirestore();
          const q = query(collection(db, "op_switchorders_otos"));
          const snapshot = await getCountFromServer(q);
          this.totalCount = snapshot.data().count;
          console.debug("Total count loaded:", this.totalCount);
        } catch (error) {
          console.error("Error loading total count:", error);
        }
      },
      // Show snackbar with a message and color.
      showSnackbar(message, color) {
        this.snackbarMessage = message;
        this.snackbarColor = color;
        this.snackbar = true;
      },
      // Move to the next document if needed.
      moveToNextIfNeeded() {
        if (this.currentIndex >= this.displayDocuments.length) {
          this.currentIndex = 0;
        }
        // If nearly at the end of the filtered list and more may be available, load another batch.
        if (this.displayDocuments.length - this.currentIndex < 5 && !this.allLoaded) {
          this.loadNextBatch();
        }
        this.preloadImages();
      },
      // Handle keydown events.
      async handleKeydown(event) {
        if (!this.currentDocument) return;
        if (event.key === "ArrowLeft") {
          await this.updateClassification("BAD");
          this.showSnackbar("Marked as BAD", "error");
          // For filtered view, leave currentIndex as is since the document gets removed from displayDocuments.
          if (!this.filterUncategorized) {
            this.currentIndex++;
          }
          this.moveToNextIfNeeded();
        } else if (event.key === "ArrowRight") {
          await this.updateClassification("GOOD");
          this.showSnackbar("Marked as GOOD", "success");
          if (!this.filterUncategorized) {
            this.currentIndex++;
          }
          this.moveToNextIfNeeded();
        } else if (event.key === "ArrowDown") {
          console.debug("Skipping document", this.currentDocument.id);
          this.showSnackbar("Skipped", "info");
          this.currentIndex++;
          this.moveToNextIfNeeded();
        }
      }
    },
    mounted() {
      window.addEventListener("keydown", this.handleKeydown);
      this.loadNextBatch();
      this.loadTotalCount();
    },
    beforeDestroy() {
      window.removeEventListener("keydown", this.handleKeydown);
    }
  };
</script>
  
<style scoped>
  .review-image {
    margin-bottom: 20px;
  }
  .instructions {
    text-align: center;
    font-size: 16px;
  }
  .instructions p {
    margin: 5px 0;
  }
</style>
