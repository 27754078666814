<template>
  <v-container>
    <h1>Delete Nested Fields + Category2 Parsing</h1>

    <!-- Document ID Input -->
    <v-text-field
      label="Enter Doc ID"
      v-model="docId"
      outlined
      clearable
    ></v-text-field>

    <!-- Single-doc Buttons -->
    <v-btn color="primary" class="ma-2" @click="readDocument">
      Read Document
    </v-btn>

    <v-btn color="error" class="ma-2" @click="deleteNestedFields">
      Delete Fields
    </v-btn>

    <v-btn color="info" class="ma-2" @click="readCategory2">
      Read Category2
    </v-btn>

    <!-- Text field for single-doc Category2 result -->
    <v-text-field
      label="Category2 Value"
      v-model="category2Value"
      outlined
      readonly
      class="mt-3"
    ></v-text-field>

    <!-- "Process All" across entire collection for delete fields -->
    <v-btn color="secondary" class="ma-2" @click="processAllDocuments" :disabled="processingAll">
      Process All (Collection)
    </v-btn>

    <!-- Existing "Validate All Category2" -->
    <v-btn color="warning" class="ma-2" @click="validateAllCategory2" :disabled="validatingAll">
      Validate All Category2
    </v-btn>

    <!-- Fill Category2 If Missing (entire collection) -->
    <v-btn color="success" class="ma-2" @click="fillCategory2IfMissingAll" :disabled="validatingAll">
      Fill Category2 If Missing
    </v-btn>

    <!-- NEW BUTTON: Fill Category2 If Missing (from file) -->
    <v-btn color="success" class="ma-2" @click="fillCategory2IfMissingAllFromFile" :disabled="validatingAll">
      Fill Category2 If Missing (From File)
    </v-btn>

    <!-- Status / Errors -->
    <v-alert
      v-if="statusMessage"
      :type="isError ? 'error' : 'success'"
      outlined
      class="mt-3"
    >
      {{ statusMessage }}
    </v-alert>

    <!-- Progress bar for "Delete Fields" -->
    <div v-if="processingAll" class="mt-4">
      <v-progress-linear
        :value="progressValue"
        height="20"
        color="blue darken-2"
        striped
        background-color="grey darken-1"
      ></v-progress-linear>
      <p class="mt-1">
        {{ totalProcessed }} / {{ totalDocs }} processed 
        ({{ Math.round(progressValue) }}%)
      </p>
      <p>Updated: {{ totalUpdated }}, Errors: {{ updateErrors }}</p>
    </div>

    <!-- Progress bar for "Validate Category2" (also reused for "fillCategory2IfMissing") -->
    <div v-if="validatingAll" class="mt-4">
      <v-progress-linear
        :value="validateProgressValue"
        height="20"
        color="orange darken-2"
        striped
        background-color="grey darken-1"
      ></v-progress-linear>
      <p class="mt-1">
        {{ validateProcessed }} / {{ validateTotalDocs }} checked 
        ({{ Math.round(validateProgressValue) }}%)
      </p>
      <p>Invalid Count: {{ validateInvalidCount }}</p>
    </div>

    <!-- Text area listing invalid docs in validation / fill -->
    <v-textarea
      v-if="invalidCategory2Docs"
      v-model="invalidCategory2Docs"
      label="Invalid Category2 Documents"
      outlined
      rows="10"
      readonly
    ></v-textarea>

    <!-- Display JSON of the fetched single doc if desired -->
    <v-card v-if="docOutput" class="mt-3 pa-3">
      <pre>{{ docOutput }}</pre>
    </v-card>
  </v-container>
</template>

<script>
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  getCountFromServer
} from "firebase/firestore"

export default {
  name: "DeleteNestedFields",
  data() {
    return {
      docId: "F250609134521V1U2300053",
      category2Value: "",
      docOutput: "",
      statusMessage: "",
      isError: false,

      // For "Delete Fields" across the entire collection
      processingAll: false,
      totalDocs: 0,
      totalProcessed: 0,
      totalUpdated: 0,
      updateErrors: 0,

      // For "Validate All" or "Fill If Missing"
      validatingAll: false,
      validateTotalDocs: 0,
      validateProcessed: 0,
      validateInvalidCount: 0,
      invalidCategory2Docs: "",

      // Track how many docs we actually updated in "fillCategory2IfMissing" 
      // so we don't overload or reuse existing counters
      validateUpdatedCount: 0
    };
  },
  computed: {
    // Progress for "Process All" (deleting fields)
    progressValue() {
      if (this.totalDocs === 0) return 0;
      return (this.totalProcessed / this.totalDocs) * 100;
    },
    // Progress for "Validate Category2" or "Fill Category2"
    validateProgressValue() {
      if (this.validateTotalDocs === 0) return 0;
      return (this.validateProcessed / this.validateTotalDocs) * 100;
    }
  },
  methods: {
    //-----------------------------------------
    // (1) Single Document Reading
    //-----------------------------------------
    async readDocument() {
      this.resetMessages();
      this.docOutput = "";

      if (!this.docId) {
        this.setError("Please enter a document ID.");
        return;
      }

      try {
        const db = getFirestore();
        const docRef = doc(db, "cs_customer_calls_v2", this.docId);
        const snapshot = await getDoc(docRef);

        if (!snapshot.exists()) {
          this.setError(`Document "${this.docId}" does not exist.`);
          return;
        }

        const docData = snapshot.data();
        this.docOutput = JSON.stringify(docData, null, 2);
        this.statusMessage = `Successfully read document "${this.docId}".`;
      } catch (error) {
        this.setError(`Error reading doc: ${error.message}`);
      }
    },

    //-----------------------------------------
    // (2) Single Document: Delete Fields
    //-----------------------------------------
    async deleteNestedFields() {
      this.resetMessages();

      if (!this.docId) {
        this.setError("Please enter a document ID.");
        return;
      }

      try {
        const db = getFirestore();
        const docRef = doc(db, "cs_customer_calls_v2", this.docId);
        const snapshot = await getDoc(docRef);

        if (!snapshot.exists()) {
          this.setError(`Document "${this.docId}" does not exist.`);
          return;
        }

        const docData = snapshot.data();
        const changed = this.removeFields(docData);
        if (!changed) {
          this.statusMessage = "No specified fields found in this document.";
          return;
        }

        // Overwrite with updated data
        await setDoc(docRef, docData, { merge: false });

        this.statusMessage = `Successfully deleted fields from "${this.docId}".`;
        this.docOutput = JSON.stringify(docData, null, 2);
      } catch (error) {
        this.setError(`Error deleting fields: ${error.message}`);
      }
    },

    //-----------------------------------------
    // (3) Single Document: Read Category2
    //-----------------------------------------
    async readCategory2() {
      this.resetMessages();
      this.category2Value = "";

      if (!this.docId) {
        this.setError("Please enter a document ID.");
        return;
      }

      try {
        const cat2Obj = await this.getCategory2(this.docId);
        if (!cat2Obj) {
          this.setError("No valid category2 found or parse error.");
          return;
        }
        // Otherwise, display final object
        this.category2Value = JSON.stringify(cat2Obj);
        this.statusMessage = "category2 found successfully.";
      } catch (error) {
        this.setError(`Error reading category2: ${error.message}`);
      }
    },

    //-----------------------------------------
    // (4) Process All (Delete Fields) in Batches
    //-----------------------------------------
    async processAllDocuments() {
  this.resetMessages();
  this.resetAllCounters();
  this.processingAll = true;

  try {
    const db = getFirestore();
    const collRef = collection(db, "cs_customer_calls_v2");
    const countSnap = await getCountFromServer(query(collRef));
    this.totalDocs = countSnap.data().count;

    let q = query(collRef, orderBy("__name__"), limit(500));
    let lastDocSnap = null;

    while (true) {
      const docsSnap = await getDocs(q);
      if (docsSnap.empty) break;

      const promises = docsSnap.docs.map(async (docSnap) => {
        this.totalProcessed++;
        let docData = docSnap.data();
        let changed = this.removeFields(docData);
        if (changed) {
          try {
            await setDoc(docSnap.ref, docData, { merge: false });
            this.totalUpdated++;
          } catch (e) {
            console.error("Error updating doc:", docSnap.id, e);
            this.updateErrors++;
          }
        }
        // Introduce a delay between document reads
        await new Promise(resolve => setTimeout(resolve, 200)); // 100ms delay
      });

      await Promise.all(promises);
      lastDocSnap = docsSnap.docs[docsSnap.docs.length - 1];
      q = query(
        collRef,
        orderBy("__name__"),
        limit(500),
        startAfter(lastDocSnap)
      );
    }

    this.statusMessage =
      `Finished processing ${this.totalDocs} documents. ` +
      `Processed: ${this.totalProcessed}, Updated: ${this.totalUpdated}, ` +
      `Errors: ${this.updateErrors}`;
  } catch (error) {
    this.setError(`Error processing all docs: ${error.message}`);
  } finally {
    this.processingAll = false;
  }
},

    //-----------------------------------------
    // (5) Validate All category2 in Batches
    //    Now calls your new getCategory2() for each doc
    //-----------------------------------------
    async validateAllCategory2() {
      this.resetMessages();
      this.resetValidateCounters();
      this.invalidCategory2Docs = "";
      this.validatingAll = true;
      let currentDocId = null;

      try {
        const db = getFirestore();
        const collRef = collection(db, "cs_customer_calls_v2");

        const countSnap = await getCountFromServer(query(collRef));
        this.validateTotalDocs = countSnap.data().count;

        let q = query(collRef, orderBy("__name__"), limit(500));
        let lastDocSnap = null;

        while (true) {
          const docsSnap = await getDocs(q);
          if (docsSnap.empty) break;

          const tasks = docsSnap.docs.map(async (docSnap) => {
            this.validateProcessed++;
            currentDocId = docSnap.id;

            try {
              const cat2Obj = await this.getCategory2(docSnap.id);
              if (cat2Obj == null) {
                this.recordInvalid(currentDocId);
              } else {
                // Optional check: e.g. is cat2Obj fully populated?
                let score = 0;
                if (cat2Obj.primary && cat2Obj.primary !== "") score += 1;
                if (cat2Obj.secondary && cat2Obj.secondary !== "") score += 1;
                if (cat2Obj.confidence) score += 1;
                console.log('Valid', docSnap.id, cat2Obj, score);
              }
            } catch (error) {
              console.error(`Error processing document ${currentDocId}:`, error);
              this.recordInvalid(currentDocId);
            }
          });

          await Promise.all(tasks);
          lastDocSnap = docsSnap.docs[docsSnap.docs.length - 1];
          q = query(
            collRef,
            orderBy("__name__"),
            limit(500),
            startAfter(lastDocSnap)
          );
        }

        this.statusMessage =
          `Validation complete. Checked ${this.validateTotalDocs} docs. ` +
          `Invalid: ${this.validateInvalidCount}`;
      } catch (error) {
        console.log('ERROR', error.message, currentDocId);
        this.setError(`Error validating category2: ${error.message}`);
      } finally {
        this.validatingAll = false;
      }
    },

    //-----------------------------------------
    // (6) Fill Category2 If Missing (entire collection)
    //-----------------------------------------
    async fillCategory2IfMissingAll() {
      this.resetMessages();
      this.resetValidateCounters();
      this.invalidCategory2Docs = "";
      this.validateUpdatedCount = 0;
      this.validatingAll = true;

      try {
        const db = getFirestore();
        const collRef = collection(db, "cs_customer_calls_v2");

        // We'll reuse the "validate..." counters for progress
        const countSnap = await getCountFromServer(query(collRef));
        this.validateTotalDocs = countSnap.data().count;

        let q = query(collRef, orderBy("__name__"), limit(500));
        let lastDocSnap = null;

        while (true) {
          const docsSnap = await getDocs(q);
          if (docsSnap.empty) break;

          // For each doc in this batch
          const tasks = docsSnap.docs.map(async (docSnap) => {
            this.validateProcessed++;
            const docId = docSnap.id;
            const docData = docSnap.data();

            // Check if reason.gemini.Categorization already exists
            if (
              docData.reasoning &&
              docData.reasoning.gemini &&
              docData.reasoning.gemini.Categorization
            ) {
              // It's already there, skip
              return;
            }

            // Otherwise, parse category2
            try {
              const catObj = await this.getCategory2(docId);
              if (!catObj) {
                this.validateInvalidCount++;
                this.invalidCategory2Docs += docId + "\n";
                return;
              }

              // If parse success => docData.reasoning.gemini.Categorization = catObj
              if (!docData.reasoning) {
                docData.reasoning = {};
              }
              if (!docData.reasoning.gemini) {
                docData.reasoning.gemini = {};
              }

              docData.reasoning.gemini.Categorization = catObj;

              // Overwrite doc
              await setDoc(docSnap.ref, docData, { merge: false });
              this.validateUpdatedCount++;
            } catch (err) {
              console.error("Error filling doc:", docId, err);
              this.validateInvalidCount++;
              this.invalidCategory2Docs += docId + "\n";
            }
          });

          await Promise.all(tasks);
          lastDocSnap = docsSnap.docs[docsSnap.docs.length - 1];
          q = query(
            collRef,
            orderBy("__name__"),
            limit(500),
            startAfter(lastDocSnap)
          );
        }

        this.statusMessage =
          `Fill completed. Checked: ${this.validateTotalDocs}. ` +
          `Updated: ${this.validateUpdatedCount}, Errors: ${this.validateInvalidCount}`;
      } catch (err) {
        this.setError(`Error filling category2: ${err.message}`);
      } finally {
        this.validatingAll = false;
      }
    },

    //-----------------------------------------
    // (7) NEW METHOD: Fill Category2 If Missing (from JSON file)
    // This reads "missing_categories.json" which contains
    // an array of doc IDs you still need to process.
    //-----------------------------------------
    async fillCategory2IfMissingAllFromFile() {
      this.resetMessages();
      this.resetValidateCounters();
      this.invalidCategory2Docs = "";
      this.validateUpdatedCount = 0;
      this.validatingAll = true;

      try {
        // 1) Fetch the list of doc IDs from missing_categories.json
        const docIds = await this.fetchMissingDocIds();
        this.validateTotalDocs = docIds.length;

        // 2) For each docId, attempt the same logic as fillCategory2IfMissingAll
        const db = getFirestore();

        for (const docId of docIds) {
          this.validateProcessed++;

          // Get doc
          const docRef = doc(db, "cs_customer_calls_v2", docId);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            // Doc doesn't exist => mark invalid, skip
            this.validateInvalidCount++;
            this.invalidCategory2Docs += docId + " (not found)\n";
            continue;
          }

          const docData = docSnap.data();
          // If doc already has reason.gemini.Categorization, skip
          if (
            docData.reasoning &&
            docData.reasoning.gemini &&
            docData.reasoning.gemini.Categorization
          ) {
            continue; // skip
          }

          // parse
          try {
            const catObj = await this.getCategory2(docId);
            if (!catObj) {
              // can't parse => mark invalid
              this.validateInvalidCount++;
              this.invalidCategory2Docs += docId + "\n";
              continue;
            }

            // If parse success => docData.reasoning.gemini.Categorization = catObj
            if (!docData.reasoning) {
              docData.reasoning = {};
            }
            if (!docData.reasoning.gemini) {
              docData.reasoning.gemini = {};
            }

            docData.reasoning.gemini.Categorization = catObj;

            // Overwrite doc
            await setDoc(docRef, docData, { merge: false });
            this.validateUpdatedCount++;
          } catch (err) {
            console.error("Error filling doc:", docId, err);
            this.validateInvalidCount++;
            this.invalidCategory2Docs += docId + "\n";
          }
        }

        // 3) Done
        this.statusMessage =
          `Fill (from file) completed. Total docIds: ${this.validateTotalDocs}. ` +
          `Updated: ${this.validateUpdatedCount}, Errors: ${this.validateInvalidCount}`;
      } catch (err) {
        this.setError(`Error filling category2 (from file): ${err.message}`);
      } finally {
        this.validatingAll = false;
      }
    },

    //-----------------------------------------
    // HELPER: fetchMissingDocIds() from /missing_categories.json
    // Make sure the file is available in your public folder
    //-----------------------------------------
    async fetchMissingDocIds() {
      try {
        const response = await fetch("/missing_categories.json");
        if (!response.ok) {
          throw new Error("Failed to load missing_categories.json");
        }
        const docIds = await response.json();
        // docIds should be an array
        return docIds;
      } catch (error) {
        console.error("Error fetching missing doc IDs:", error);
        throw error;
      }
    },

    //-----------------------------------------
    // HELPER #0: getCategory2
    //-----------------------------------------
    async getCategory2(docId) {
      const db = getFirestore();
      const primaryRaw = await this.getCategory2Raw(db, "cs_customer_calls_v2", docId);
      const fallbackRaw = await this.getCategory2Raw(db, "cs_call_analysis_v2", docId);
      
      if (primaryRaw == '{"response": "null"}' && fallbackRaw == '{"response": "null"}') {
        return null;
      }
      if (primaryRaw == null && fallbackRaw == null) {
        return null;
      }
      if (!primaryRaw && !fallbackRaw) {
        return null;
      }
      
      let primaryObj = this.parseManual(primaryRaw, docId);
      let fallbackObj = this.parseManual(fallbackRaw, docId);
      
      if (!primaryObj && !fallbackObj) {
        return null;
      }
      
      if (primaryObj && primaryObj.primary && primaryObj.secondary) {
        return primaryObj;
      } else {
        return fallbackObj;
      }
    },

    async getCategory2Raw(db, collectionName, docId) {
  if (!docId) return null;
  const docRef = doc(db, collectionName, docId);
  
  let attempts = 0;
  const maxAttempts = 2; // Maximum number of retry attempts
  const delay = 100; // Delay in ms

  while (attempts < maxAttempts) {
    try {
      const snapshot = await getDoc(docRef);
      if (!snapshot.exists()) {
        return null;
      }
      const data = snapshot.data();
      console.log('Document Data:', data); // Log the entire document data
      const gemini = data.reasoning?.gemini;
      if (!gemini || !gemini.category2) {
        attempts++;
        if (attempts < maxAttempts) {
          console.warn(`Attempt ${attempts}: gemini.category2 is null, retrying...`);
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        } else {
          return null;
        }
      }
      // Ensure category2 is a string before calling trim
      if (typeof gemini.category2 === 'string') {
        return gemini.category2.trim();
      } else {
        console.warn('category2 is not a string:', gemini.category2);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching document ${docId}:`, error);
      return null;
    }
  }
  return null;
},

parseManual(raw, docId) {
  if (!raw) return null;
  try {
    const topLevel = JSON.parse(raw);
    if (!topLevel || !topLevel.response) return null;
    let responseStr = topLevel.response.trim();
    if (responseStr.startsWith("{")) {
      responseStr = responseStr.slice(1);
    }
    if (responseStr.endsWith("}")) {
      responseStr = responseStr.slice(0, -1);
    } else {
      if (responseStr.endsWith(":")) {
        responseStr = responseStr + '"", "Confidence": 0'
      }
    }
    let arr = responseStr.split(",");
    arr = arr.filter(item => item.includes('":'));
    arr = arr.map(item => item.replace(/"/g, ''));
    arr = arr.map(item => item.split(':'));

    let finalObj = {
      primary: "",
      secondary: "",
      definition: "",
      logic: "",
      confidence: 0
    };
    arr.forEach(([k, v]) => {
      k = (k || "").toLowerCase();
      v = (v || "").trim();
      if (k.includes("main")) finalObj.primary = v;
      if (k.includes("sub")) finalObj.secondary = v;
      if (k.includes("definition")) finalObj.definition = v;
      if (k.includes("logic")) finalObj.logic = v;
      if (k.includes("confidence")) {
        let c = parseFloat(v);
        if (c > 1) c = c / 100;
        finalObj.confidence = +c.toFixed(2);
      }
    });
    return finalObj;
  } catch (err) {
    console.warn("parseManual error:", err, raw, docId);
    return null;
  }
},

    //-----------------------------------------
    // HELPER #4: remove fields
    //-----------------------------------------
    removeFields(docData) {
      let changed = false;
      if (docData.reasoning && docData.reasoning.foo) {
        delete docData.reasoning.foo;
        changed = true;
      }
      if (docData.reasoning && Object.keys(docData.reasoning).length === 0) {
        delete docData.reasoning;
        changed = true;
      }
      return changed;
    },

    //-----------------------------------------
    // HELPER #5: Mark doc invalid
    //-----------------------------------------
    recordInvalid(docId) {
      this.validateInvalidCount++;
      this.invalidCategory2Docs += docId + "\n";
    },

    //-----------------------------------------
    // HELPER #6: Reset states
    //-----------------------------------------
    resetAllCounters() {
      this.totalDocs = 0;
      this.totalProcessed = 0;
      this.totalUpdated = 0;
      this.updateErrors = 0;
    },
    resetValidateCounters() {
      this.validateTotalDocs = 0;
      this.validateProcessed = 0;
      this.validateInvalidCount = 0;
      this.invalidCategory2Docs = "";
    },
    resetMessages() {
      this.statusMessage = "";
      this.isError = false;
    },
    setError(msg) {
      this.isError = true;
      this.statusMessage = msg;
    }
  }
};
</script>

<style scoped>
</style>
