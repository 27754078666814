<template>
  <v-tabs vertical>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-lightbulb
      </v-icon>
      Idea
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-drawing
      </v-icon>
      Proof of Concept
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-briefcase-account
      </v-icon>
      Business Case
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-bullseye-arrow
      </v-icon>
      Goals
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-memory
      </v-icon>
      Approach
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-check-decagram
      </v-icon>
      Evaluation
    </v-tab>
    <v-tab class="justify-start">
      <v-icon left>
        mdi-pipe
      </v-icon>
      Pipeline
    </v-tab>

    <!-- ------------ Idea ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Idea</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <div class="text-h6 text-center ml-12">Can we extract useful insights from our historical call recordings?
            </div>
          </v-sheet>
          <p>
            Hydro Ottawa maintains a large repository of call recordings from customer interactions. Over 6 years, we
            have recorded 2,159,738 calls.
            If we could feed this data into a large language model then we could extract useful insights from the data
            that would otherwise be cost prohibitive to obtain.
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    
    <!-- ------------ Business Case ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Business Case</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <ul>
              <li class="subtitle-1">Topic Categorization</li>
              <li class="subtitle-1">Understanding Sentiment</li>
              <li class="subtitle-1">Issue Identification, Resolution, and Recommendations</li>
              <li class="subtitle-1">Agent Performance and Training</li>
              <li class="subtitle-1">Operational Efficiency & Self-serve</li>
              <li class="subtitle-1">Predictive Analysis</li>
            </ul>
          </v-sheet>
          <h3 class="mt-12 mb-6">Topic Categorization</h3>
          <p>
          <ul>
            <li>Automatically classifying calls into predefined topics or categories.</li>
            <li>Identifying recurring themes or subjects in conversations.</li>
            <li>Facilitating targeted analysis and reporting based on call topics (including productivity/efficiency
              measures like average handling time)</li>
            <li>Enabling sharpened understanding of CSAT based on customer intent (categorization) to truly understand
            </li>
            <li>Identify opportunities to optimize call routing (IVR)</li>
            <li>
              Today: agent categorizes based on a pick list - issue, too broad, misclassification, no entries .
              <ul>
                <li>For example: Billing is the top category ringing in at a whopping 37% of all the calls - question is
                  - what about billing is the inquiry about - can we isolate a true "High bill" inquiry from one that
                  simply indicates the customer doesnt understand their bill, or its that the usage is not high, but
                  rather they didnt pay last month's bill, or their OESP support stopped, etc..</li>
                <li>Another example : could have multiple topics in call journey - customer called to move (thats one
                  intent), he has to choose a rate option that requires an explanation (thats another intent - rate
                  option selection), etc. If we could identify and summarize the different categories/calls we could
                  then pick out , say "rate option" and focus on those to understand whether we have effective agent
                  articulation through a customers lens, and/or whether relevant online tools were promoted all to help
                  inform customer. We could develop workshops/trainig depending on the topic.</li>
                <li>We could also expand the topics to monitor as relevant, configured, dynamic with no impact or effort
                  on agent's part</li>
                <li>New topics could be recommended by the AI - especially around new trends; inquiries etc</li>
              </ul>
            </li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Understanding Sentiment</h3>
          <p>
          <ul>
            <li>Detecting customer emotions (e.g., frustration, satisfaction).</li>
            <li>Assessing the tone and mood of both the customer and the agent.</li>
            <li>Identifying shifts in sentiment during the call.</li>
            <li>
              Benefits:
              <ul>
                <li>Enhanced Training and Coaching: Use insights from sentiment analysis to inform agent training and
                  coaching. Focus on areas like empathy, conflict resolution, and customer engagement.; Develop targeted
                  training programs to improve emotional intelligence and customer handling skills of the agents.
                  Evaluate agent performance in handling different emotional contexts and use this data to drive
                  improvement at agent level</li>
                <li>Improved Customer Experience and Satisfaction:Understand the factors leading to positive or negative
                  sentiments, allowing us to refine programs, services, and customer interactions; Implement changes
                  that proactively address common customer pain points</li>
                <li>Feedback and Performance Analysis:Analyze sentiment trends over time to assess the impact of policy
                  changes, new products, or marketing campaigns on customer sentiment; Evaluate agent performance in
                  handling different emotional contexts and use this data in performance reviews and recognition
                  programs.</li>
                <li>Crisis Management and Brand Reputation: identify and address widespread issues or negative sentiment
                  trends that could lead to a public relations crisis.; Protect and enhance brand reputation by
                  demonstrating responsiveness and a customer-centric approach.</li>
              </ul>
            </li>
            <li>
              <strong>Advanced:</strong> Predictive Analytics and Proactive Solutions: Utilize patterns in sentiment
              data to predict potential customer issues before they escalate, allowing for proactive intervention.;
              Identify at-risk customers early and reach out with solutions to resolve issues or improve their
              experience
            </li>
            <li>
              <strong>Live Sentiment Analysis</strong>
              <ul>
                <li>Personalized Customer Service: Live: Tailor the conversation and service approach based on the
                  detected sentiment of the customer, leading to a more personalized and empathetic interaction.; Agents
                  can adjust their tone and approach in real-time, aligning with the customer's emotional state to build
                  rapport and trust ;</li>
                <li>Real-time Support and Intervention: Identify calls where customers are showing signs of frustration
                  or dissatisfaction and flag them for immediate attention or escalation.; Offer real-time support to
                  agents during challenging calls, such as providing them with prompts or guidance on how to de-escalate
                  situations.</li>
              </ul>
            </li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Issue Identification, Resolution, and Recommendations</h3>
          <p>
          <ul>
            <li>Detecting specific issues or problems mentioned in the conversation.</li>
            <li>Analyzing common issues across calls to improve product or service.</li>
            <li>Suggesting potential solutions or responses to the agent in real-time.(eventually)</li>
            <li>Recommending promotional opportunities where appropriate.</li>
            <li>Tailoring interactions based on customer history and preferences.</li>
            <li>Predicting and preempting potential customer issues.</li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Agent Performance and Training</h3>
          <p>
          <ul>
            <li>Analyzing agent performance for training and development purposes.</li>
            <li>Identifying best practices and areas for improvement.</li>
            <li>Customizing training programs based on individual agent needs.</li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Operational Efficiency & Self-serve</h3>
          <p>
          <ul>
            <li>Understand reasons for repeat calls</li>
            <li>First call resolution</li>
            <li>self-serve opportunities</li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Predictive Analytics</h3>
          <p>
          <ul>
            <li>Anticipating future trends and customer needs based on call data.</li>
            <li>Informing strategic decisions through comprehensive analysis.</li>
            <li>Identifying opportunities for innovation in products and services.</li>
          </ul>
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Goals ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Goals</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <ul>
              <li class="subtitle-1">Natural Search</li>
              <li class="subtitle-1">Conversational Search</li>
              <li class="subtitle-1">Dashboard</li>
              <li class="subtitle-1">Call Classification</li>
              <li class="subtitle-1">Recommendations</li>
              <li class="subtitle-1">Journeys</li>
              <li class="subtitle-1">Trends</li>
            </ul>
          </v-sheet>
          <h3 class="mt-12 mb-6">Natural Search</h3>
          <p>
          <ul>
            <li>Query the entire dataset with natural language queries</li>
            <ul>
              <li>ex. how many records mention automatic payments</li>
              <li>ex. return records that mention call quality</li>
            </ul>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Conversational Search</h3>
          <p>
          <ul>
            <li>Users may directly converse with the data itself</li>
            <ul>
              <li color="red">Restricted to the token limit of the model</li>
              <li>Provides an interactive chat interface </li>
            </ul>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Dashboard</h3>
          <p>Simple graphs and lists which summarize pre-calculated data such as agent sentiment</p>
          <h3 class="mt-12 mb-6">Call Classification</h3>
          <p>
          <ul>
            <li>Classifies based on recommended categories (multiple, weighted)</li>
            <li>Classify based on the known categories:Billing, Moves, Not Coded by Agent, Acct Maintenance,
              Collections, Payment, MyAccount, Operations,
              Other, Reconnections, Pre-Authorized Payment Plan, Social Assist, Forestry, LLR, Outages, Booking, Battery
              Loan Inquiry, Rate Option, Document Request,
              Surveys, MOBILE APP, Layouts, Outage Alert Inq, Scam, Usage Inq, CEAPSB, Next Level Complaint, Deposit
              Inquiry, Complaint & Escalations,
              Cancelling, EV Inquiry, LD, PROMOTION, IVR Self-Service, Usage Online, CEAP, Home Energy Reports, CDM,
              Green Button, Supervisions
            </li>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Recommendations</h3>
          <p>
          <ul>
            <li>Users may directly converse with the data itself</li>
            <ul>
              <li color="red">Restricted to the token limit of the model</li>
              <li>Provides an interactive chat interface </li>
            </ul>
          </ul>
          </p>
          <h3 class="mt-12 mb-6">Journeys</h3>
          <p>
            Attempt to extract customer jouneys. We start by identifying call resolutions and link them to call entries.
          </p>
          <h3 class="mt-12 mb-6">Trends</h3>
          <p>
            Provide graphs for pre-defined trends topics. For example, we could provide a graph of the number of calls
            that mention "carbon tax" over time.
            Ultimately, trends will be used to power an alerting system.
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Approach ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Approach</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <div class="text-h6 text-center ml-12">
              Speech-to-text, Natural Language Processing, Machine Learning
            </div>
          </v-sheet>
          <p>
          <ul>
            <li>Obtain sample audio files</li>
            <li>Analyze speech-to-text models</li>
            <li>Analyze LLM models</li>
            <li>Process sample files</li>
            <li>Share results</li>
            <li>Gain access to full data set</li>
            <li>Develop pipeline</li>
            <li>Process 1 year of data</li>
            <li>Create interactive app</li>
          </ul>
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Evaluation ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Evaluation</h2>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Google: Long + Gemini Pro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription</h3>
                <ul>
                  <li>hi this is Richard from Hydro Ottawa how can help you</li>
                  <li>and the last one I just received there was a large amount and I wondered why and then noticed that
                    our automated withdrawal stopped all of a sudden last November</li>
                  <li>I see okay and we didn't do anything to stop that we had moved in July and we had called and got
                    the address change and done all that</li>
                  <li>so now we've been charged some interest and I'd like to have that looked at and interest removed
                    so we can just pay what we're owed owing what's going</li>
                  <li>okay let's see here can I see the what's the address</li>
                  <li>it's</li>
                  <li>the GRE and us</li>
                  <li>apostrophe s</li>
                  <li>and</li>
                  <li>yes okay and can I verify</li>
                  <li>the date of birth</li>
                  <li>September 3rd 1960</li>
                  <li>okay can I verify the phone number on the file</li>
                  <li>6 1 3 9 9 0 7 7 1 5 that might be one of them</li>
                  <li>so she doesn't last payment receive as we back in October it looks like it was three bills
                    altogether but the interest honestly it wasn't that much was really a dollar 52 yeah I know but I
                    mean the fact</li>
                  <li>we didn't make this change I don't really feel we should pay any interest I'd like to get it
                    rectified and you know I could take the dollar fifty two off it's fine but yeah okay great look for
                    you</li>
                  <li>you can you see why the automated which are all was</li>
                  <li>turned off</li>
                  <li>20 years plus</li>
                  <li>you</li>
                  <li>okay so it looks like</li>
                  <li>oh yeah there you go cancel the interest that's fine so now the remaining balance is 254 dollars
                    and seventy cents</li>
                  <li>and the reason why the auto pay was canceled</li>
                  <li>yeah since it has been 2007 until 23 so yeah so in the system it always it always after so many
                    years it stops the</li>
                  <li>the auto pay after so many years okay well there should be some sort of notice or something right
                    when you first sign up for the autoplay on the form it says that after why that's a long time I know
                    that's a long time to know and because it's automated payments you know it</li>
                  <li>and I kept every invoice and notice from Hydro Ottawa nothing like that just a suggestion it might
                    be good to set up some sort of notice that tells people that</li>
                  <li>all that or do I have to make a payment oh you have to make a payment man you'll see for this one
                    here</li>
                  <li>haha figured that okay</li>
                  <li>all right so can you reactiv</li>
                  <li>ate automated withdrawal for the next bill okay so you have to do that I can activate it so the
                    customer has to do that okay I'm online so where do I go to do that you</li>
                  <li>okay I did it myself here okay I just in 2027</li>
                  <li>okay</li>
                  <li>okay yeah there you go okay now it's active again but then this yeah but disappearing has to be
                    paid manually</li>
                  <li>so when will I see the revised amount so that I can pay the bill cuz that's a pretty fresh my site
                    and it's not you get on there within give it like an hour sort of the system</li>
                  <li>okay</li>
                  <li>okay thanks very much no worries you have yourself a good one okay okay YouTube bye now</li>
                </ul>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Gemini Pro)</h3>
                  <strong>1. Detailed summary of the call</strong><br>
                  The customer, Richard, called Hydro Ottawa to inquire about a large bill he received. He noticed that
                  his automated withdrawal had stopped in November, resulting in interest charges. Richard explained
                  that he had moved in July and had updated his address with Hydro Ottawa. The customer service
                  representative confirmed that the automated withdrawal had been canceled due to the account being
                  inactive for over 20 years. The representative agreed to remove the interest charges and reinstated
                  the automated withdrawal. The customer made a manual payment for the outstanding balance and was
                  advised to check his online account within an hour to see the revised amount.<br><br>
                  <strong>2. Topic categorization</strong><br>
                  Billing<br><br>
                  <strong>3. Sentiment analysis</strong><br>
                  Overall, the sentiment of the call is positive. The customer was frustrated with the situation but the
                  customer service representative was helpful and resolved the issue.<br><br>
                  <strong>4. Issue Identification, Resolution and recommendation</strong><br>
                  <strong>Issue:</strong> Automated withdrawal was canceled, resulting in interest charges.<br>
                  <strong>Resolution:</strong> The customer service representative removed the interest charges and
                  reinstated the automated withdrawal.<br>
                  <strong>Recommendation:</strong> Hydro Ottawa should consider sending a notice to customers when their
                  automated withdrawal is canceled.<br><br>
                  <strong>5. Agent Performance</strong><br>" +
                  The customer service representative was helpful and resolved the issue to the customer's satisfaction.
                  The representative was polite and professional.<br><br>
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="warning" class="mt-6">
                  The call summary was inaccurate. The call was successfully classified as a billing inquiry. The
                  sentiment of the call was positive, and the issue was resolved to the customer's satisfaction. The
                  customer service representative demonstrated good performance and professionalism.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Google: Telephony v2 + Gemini Pro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription</h3>
                <ul>
                  <li>hi this is Richard from Hydro Ottawa how can I help you</li>
                  <li>and um the last 1 I just received there was a large amount and I wondered why and then noticed
                    that our automated withdrawal stopped all of a sudden uh last November</li>
                  <li>I see okay uh last and we didn't do anything to stop that we had moved in July and we had called
                    and got the address changed and done all that</li>
                  <li>so um now we've been charged some interest and I'd like to have that looked at and interest
                    removed so we can just pay what we're owed owing what's going</li>
                  <li>okay uh let's see here um can I see the uh what's the address</li>
                  <li>it's uh</li>
                  <li>just like g r e n s</li>
                  <li>apostrophe s okay</li>
                  <li>if you go in</li>
                  <li>yes yes</li>
                  <li>okay and can I verify uh</li>
                  <li>the date of birth</li>
                  <li>septembar 3rd 1960 okay and can I verify the phone number on the file</li>
                  <li>yeah I see there's a there was a last payment received was way back in October it looks like it
                    was 3 bills altogether but the interest honestly it wasn't that much it was only a dollar 52 uh yeah
                    I know but I mean the fact that we didn't make this change I don't really feel we should pay any
                    interest I just like to get it rectified and you know I could take the I could take the dollar 52
                    off it's fine but uh yeah okay great that for you</li>
                  <li>do can you see why the um automated which are all was</li>
                  <li>turned off</li>
                  <li>cuz we've had this for like 20 years plus</li>
                  <li>okay</li>
                  <li>so it looks like um</li>
                  <li>all right yeah there you go I cancel the interest that's fine okay so now the remaining balance is
                    254.70</li>
                  <li>and the reason why the auto pay was cancelled</li>
                  <li>yeah since it has been 2007 till 2023 so yeah um so in the system and always it always after so
                    many years it stops the uh</li>
                  <li>the auto pay after so many years okay well there should be some sort of notice or something right
                    when you first sign up for the auto pay on the form it says that after a few uh uh while I'm at the
                    V yeah that's a long time I know I know that's a long time to know and because it's automated
                    payments you know it you know</li>
                  <li>and I've kept every um invoice and notice from Hydro Ottawa nothing like that just a suggestion it
                    might be good to set up some sort of notice that tells people that</li>
                  <li>will automatically withdraw that or do I have to make a payment oh you have to make a payment
                    manually uh for this 1 here</li>
                  <li>uh-huh figured that okay</li>
                  <li>all right so can you reactivate the automated withdrawal for the next bill</li>
                  <li>okay so you'd have to do that um I can activate it the the customer has to do that um okay I'm
                    online so where do I go to do that okay let me see here 1 second</li>
                  <li>oh okay no no no okay I did it myself here okay I just I just now it ends in 2027</li>
                  <li>okay</li>
                  <li>okay yeah there you go okay now it's active again but then this yeah but this payment has to be
                    paid manually</li>
                  <li>okay so when will I see the revised amount so that I can pay the bills</li>
                  <li>refresh my site and it's not</li>
                  <li>uh you should see it on there within give it like an hour or so to update the system okay okay
                  </li>
                  <li>okay okay okay thanks very much know where is you have yourself a good 1 okay okay you too bye now
                    bye bye</li>
                </ul>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Gemini Pro)</h3>
                  The customer, Richard, contacted Hydro Ottawa because he received a large bill with interest charges.
                  He realized that his automated payment had stopped in November without his knowledge, and he wanted to
                  have the interest removed and the automated payment reactivated. The agent investigated and confirmed
                  that the automated payment had been deactivated because it had been active for over 20 years. The
                  agent removed the interest charges and reactivated the automated payment. The customer was instructed
                  to manually pay the current bill and that the updated balance would be reflected on his account within
                  an hour.<br><br>
                  <strong>Topic Categorization</strong><br>
                  Payment<br><br>
                  <strong>Sentiment Analysis</strong><br>
                  Richard was initially frustrated due to the large bill and interest charges. However, he became more
                  positive when the agent removed the interest and reactivated the automated payment.<br><br>
                  <strong>Issue Identification, Resolution and Recommendation</strong><br>
                  <strong>Issue:</strong> Automated payment was deactivated without the customer's knowledge, resulting
                  in interest charges.<br>
                  <strong>Resolution:</strong> The agent removed the interest charges and reactivated the automated
                  payment.<br>
                  <strong>Recommendation:</strong> Hydro Ottawa should consider providing a notice to customers before
                  their automated payments are deactivated.<br><br>
                  <strong>Agent Performance</strong><br>
                  The agent was helpful and resolved the issue promptly. They were also able to provide additional
                  information about automated payment deactivation.
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="warning" class="mt-6">
                  The call summary was inaccurate. The call was successfully classified as a payment inquiry. The
                  sentiment of the call was positive, and the issue was resolved to the customer's satisfaction. The
                  customer service representative demonstrated good performance and professionalism.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Google: Chirp Telephony v2 + Gemini Pro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription</h3>
                <ul>
                  <li>hi this is richard from hydroadowa how can help you hi richard this is diane i received a a bill
                    my bills are online my uh invoices and um the last one i just received there was a large amount and
                    i wondered why and then noticed that our automated withdraw stopped all of a sudden uh last november
                    i see okay uh and we didn't do anything to stop</li>
                  <li>that we had moved in july and we had called and got the address changed and done all that so um
                    now we've been charged some interest and i'd like to have that looked at and interest removed so we
                    can just pay what we're owed owing what's owing okay uh let's see here um can i see the uh what's
                    the address it's um 3478 greens road</li>
                  <li>it's like g r e n s apostrophe s okay and is it in yes okay and can i verify uh the data of birth
                    september 3rd 1960 okay and can i verify the phone number on the fl 613 990715</li>
                  <li>that might be one of them yeah i see there's a was last payment we received was way back in
                    october it looks like it was three bills all together but the interest honestly it wasn't that much
                    it was only $1.52 uh yeah i know but i mean the fact that we didn't make this change i don't really
                    feel we should pay any interest i just like to get it rectified and i could take the i could take
                    the dollar 52 off it's fine but uh yeah okay that's for you</li>
                  <li>can you see why the um automated withdraw was turned off cuz we've had this for like 20 years
                    pluss okay okay</li>
                  <li>so looks like um all right yeah there you go i cancel the interest that's fine so now the
                    remaining balance is $254.70 and the reason why the autoop was can't sold yeah since it has um 2007
                    to 2023 so yeah um so in the system it always it always after so many years it it stops the uh</li>
                  <li>the autopay after so many years okay well there should be some sort of notice or something right
                    when you first sign up for the autoop on the form it says it after why yeah that's a long time i i
                    know that's a long time to know and because it's automated payments you know it and i've kept every
                    um invoice and notice from hydro autowalk nothing like that just a suggestion it might be good to
                    set up some sort of notice but</li>
                  <li>tells people that yeah yeah okay we'll do that okay so yeah the runing balance it's uh now $254.70
                    okay okay so can you automatically withdraw that or do i have to make a payment oh you have to make
                    payment manually for this one yeah uh-huh figured that okay all right so can you reactivate the
                    automated withdraw for the next bill okay so you'd have to</li>
                  <li>do that um i can't activate it the customer has to do that um okay i'm online so where do i go to
                    do that let's see here one second i'm not happy about that okay no never mind okay i did it myself
                    here okay i just extended the date now to now so now it ends in 2027 okay</li>
                  <li>okay yeah there you go okay now it's active again but then this yeah but this has to be p manually
                    okay so when will i see the revised amount so that i can pay the bill buse i just refreshed my site
                    and it's not uh you should see it on there within give it like an hour or so to update in the system
                    okay okay okay okay thanks very much no worries you have yourself a good one okay okay you too</li>
                </ul>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Gemini Pro)</h3>
                  <strong>1. Summary</strong><br>
                  Diane called Hydro Ottawa with an inquiry about a recent bill that had a large amount due. She
                  discovered that, although she had changed her address, the automated withdrawal had stopped in
                  November and had not been reinstated. Diane requested that the interest charge of $1.52 be removed and
                  inquired about the reason for the automated withdrawal being turned off. Hydro Ottawa confirmed that
                  this happened after the automated payment method had been in effect since 2007. The agent cancelled
                  the late payment fee and reactivated the automated withdrawal for the next bill. The revised amount
                  has not yet shown on Diane's online account, but should be updated within an hour.<br><br>
                  <strong>2. Topic Categorization</strong><br>
                  Billing<br><br>
                  <strong>3. Sentiment Analysis</strong><br>
                  Overall, the call had a positive sentiment. While the customer was initially concerned about the
                  incorrect billing and interest charge, she was pleased with the agent's assistance in resolving the
                  issue promptly.<br><br>
                  <strong>4. Issue Identification, Resolution, and Recommendations:</strong><br>
                  <strong>Issue Identification:</strong><br>
                  Incorrect billing amount due to missed automated withdrawals<br>
                  Interest charge on unpaid balance<br>
                  <strong>Resolution:</strong><br>
                  Interest charge was removed<br>
                  Automated withdrawals were reactivated<br>
                  Revised balance was updated on the customer's online account within an hour<br>
                  <strong>Recommendation:</strong><br>
                  Set up a system to notify customers when their automated withdrawal is turned off after a certain
                  number of years<br><br>
                  <strong>5. Agent Performance:</strong><br>
                  The agent's performance was excellent. He/she handled the customer's concerns promptly, and resolved
                  the issue efficiently. The agent was also polite and helpful, and provided the customer with clear
                  instructions on how to reactivate her automated withdrawals.
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="success" class="mt-6">
                  The call summary was accurate. The call was successfully classified as a billing inquiry. The
                  sentiment of the call was positive, and the issue was resolved to the customer's satisfaction. The
                  customer service representative demonstrated good performance and professionalism.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Google: Chirp v2 + Gemini Pro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription</h3>
                <ul>
                  <li>hi this is richard from hydroadowa how can help you hi richard this is diane i received a a bill
                    my bills are online my uh invoices and um the last one i just received there was a large amount and
                    i wondered why and then noticed that our automated withdraw stopped all of a sudden uh last november
                    i see okay uh and we didn't do anything to stop</li>
                  <li>that we had moved in july and we had called and got the address changed and done all that so um
                    now we've been charged some interest and i'd like to have that looked at and interest removed so we
                    can just pay what we're owed owing what's owing okay uh let's see here um can i see the uh what's
                    the address it's um 3478 greens road</li>
                  <li>is like g r e e n s apostrophe s okay and is it going yes okay and can i verify uh the data of
                    birth september 3rd 1960 okay and can i verify the phone number on the file 613 990715</li>
                  <li>that might be one of them yeah i see there's a was last payment we received was way back in
                    october it looks like it was three bills all together but the interest honestly it wasn't that much
                    it was only $1.52 uh yeah i know but i mean the fact that we didn't make this change i don't really
                    feel we should pay any interest i just like to get it rectified and i could take the i could take
                    the dollar 52 off it's fine but uh yeah okay that's for you</li>
                  <li>can you see why the um automated withdraw was turned off cuz we've had this for like 20 years
                    pluss okay okay</li>
                  <li>so looks like um all right yeah there you go i cancel the interest that's fine so now the
                    remaining balance is $254.70 and the reason why the autoop was can't sold yeah since it has um 2007
                    to 2023 so yeah um so in the system it always it always after so many years it it stops the uh</li>
                  <li>the autopay after so many years okay well there should be some sort of notice or something right
                    when you first sign up for the autoop on the form it says after a while yeah that's a long time i i
                    know that's a long time to know and because it's automated payments you know it and i've kept every
                    um invoice and notice from hydro autowalk nothing like that just a suggestion it might be good to
                    set up some sort of notice that</li>
                  <li>tells people that yeah yeah okay we'll do that okay so yeah the runing balance it's uh now $254.70
                    okay okay so can you automatically withdraw that or do i have to make a payment oh you have to make
                    payment manually for this one yeah uh-huh figured that okay all right so can you reactivate the
                    automated withdraw for the next bill okay so you'd have to</li>
                  <li>do that um i can't activate it the customer has to do that um okay i'm online so where do i go to
                    do that let's see here one second i'm not happy about that okay no never mind okay i did it myself
                    here okay i just extended the date now to now so now it ends in 2027 okay</li>
                  <li>okay yeah there you go okay now it's active again but then this yeah but this has to be pit
                    manually okay so when will i see the revised amount so that i can pay the bill buse i just refreshed
                    my site and it's not uh you should see it on there within give it like an hour or so to update in
                    the system okay okay okay okay thanks very much no worries you have yourself a good one okay okay
                    you too</li>
                </ul>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Gemini Pro)</h3>
                  <strong>1. Summary of the Call</strong><br>
                  A customer named Diane called Hydro Ottawa to inquire about a large amount on her recent bill and
                  interest charges. She explained that her automated withdrawals for bill payments had stopped without
                  her knowledge and wanted the interest charges removed. The agent cancelled the interest charges and
                  explained that the automated withdrawals had stopped because the feature had expired after many years.
                  The agent suggested Diane reactivate the automated withdrawals, which she did herself.<br><br>
                  <strong>2. Topic Categorization</strong><br>
                  Billing<br><br>
                  <strong>3. Sentiment Analysis</strong><br>
                  The customer's sentiment was initially negative due to the unexpected interest charges and the
                  inconvenience of having to manually make a payment. However, the agent's prompt and helpful response
                  improved her mood, and she expressed satisfaction with the resolution.<br><br>
                  <strong>4. Issue Identification, Resolution, and Recommendation:</strong><br>
                  <strong>Issue:</strong><br>
                  Interest charges on the bill and automated withdrawals had stopped.<br>
                  <strong>Resolution:</strong><br>
                  Interest charges were cancelled.<br>
                  Automated withdrawals were reactivated.<br>
                  <strong>Recommendation:</strong><br>
                  Hydro Ottawa should implement a system to notify customers when automated withdrawals are about to
                  expire.<br><br>
                  <strong>5. Agent Performance:</strong><br>
                  The agent was polite, empathetic, and efficient. They quickly identified the issue, resolved it
                  promptly, and provided clear instructions to the customer. The agent also provided additional
                  information and suggestions to improve the customer's experience.
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="success" class="mt-6">
                  The call summary was accurate. The call was successfully classified as a billing inquiry. The
                  sentiment of the call was positive, and the issue was resolved to the customer's satisfaction. The
                  customer service representative demonstrated good performance and professionalism.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Hybrid: Whisper + Gemini Pro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription (OpenAI Whisper)</h3>
                <p>
                  Hi, this is Richard from Hydro Ottawa. How can I help you? Hi Richard, this is Diane. I received a
                  bill. My bills are online, my invoices. And the last one I just received, there was a large amount and
                  I wondered why. And then noticed that our automated withdrawal stopped all of a sudden last November.
                  I see, okay. And we didn't do anything to stop that. We had moved in July and we had called and got
                  the address changed and done all that. So now we've been charged some interest and I'd like to have
                  that looked at and the interest removed so we can just pay what we're owing. Okay, let's see here. Can
                  I see the address? It's 3478 Greens Road. Is that G-R-E-N-S? Apostrophe S. Okay. And is it Diane? Yes.
                  Okay, and can I verify the date of birth? September 3, 1960. Okay, and can I verify the phone number
                  on the file? 613-990-7715. That might be one of them. Okay. Yeah, I see there was a last payment
                  received was way back in October and it looks like there was three bills altogether. But the interest,
                  honestly, wasn't that much. It was only $1.52. Yeah, I know, but I mean the fact that we didn't make
                  this change, I don't really feel we should pay any interest. I'd like to get it rectified. I could
                  take the $1.52 off. It's fine. Yeah, okay, great. Can you see why the automated withdrawal was turned
                  off? Because we've had this for like 20 years plus. Okay, one second. Okay, so it looks like, oh,
                  yeah, there you go. I canceled the interest. That's fine. Okay. So now the remaining balance is
                  $254.70. The reason why the auto pay was canceled, yeah, since it has, so it was from 2007 until 2023.
                  So, yeah, so in the system it always, after so many years, it stops the auto pay after so many years.
                  Okay, well, there should be some sort of notice or something, right? When you first sign up for the
                  auto pay on the form, it says that after a while you have to be. That's a long time. I know. That's a
                  long time to know. And because it's automated payments, you know, and I've kept every invoice and
                  notice from Hydro Ottawa, nothing like that. Just a suggestion, it might be good to set up some sort
                  of notice that tells people that. Yeah, yeah. Okay, I think we'll do that. Okay, so, yeah, the
                  remaining balance now is only $254.70. Okay. Okay, so can you automatically withdraw that or do I have
                  to make a payment? Oh, you have to make a payment manually for this one, yes. Uh-huh, figured that.
                  Okay. All right, so can you reactivate the automated withdrawal for the next bill? Okay, so you'd have
                  to do that. I can't activate it. The customer has to do that. Okay, I'm online, so where do I go to do
                  that? Let me see here one second. I'm not happy about that. Okay, no, never mind. Okay, I did it
                  myself here. Okay, I just extended the date now to, so now it ends in 2027. Okay. Okay, yeah, there
                  you go. Okay, now it's active again. Yeah, but this has to be paid manually. Okay, so when will I see
                  the revised amount so that I can pay the bill? Because I just refreshed my site and it's not. You
                  should see it on there within, give it like an hour or so to update the system. Okay. Okay. Okay.
                  Okay, thanks very much. No worries. You have yourself a good one. Okay. Okay, you too. Bye now. Bye
                  now.
                </p>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Gemini Pro)</h3>
                  <strong>1. Detailed Summary of the Call</strong><br>
                  The customer, Diane, called Hydro Ottawa to inquire about a large amount on her recent bill and to
                  have interest charges removed. She explained that her automated withdrawal had stopped in November
                  without her knowledge and that she had moved in July and had updated her address. The agent identified
                  that the auto pay had stopped due to inactivity after a certain number of years and canceled the
                  interest charges on the account. The agent also advised the customer that she would need to make a
                  manual payment for the current bill and reactivate the automated withdrawal herself through her online
                  account.<br><br>
                  <strong>2. Topic Categorization</strong><br>
                  Billing<br><br>
                  <strong>3. Sentiment Analysis:</strong><br>
                  Overall, the sentiment of the call is positive. The customer was initially concerned about the
                  interest charges but was satisfied with the agent's resolution. However, she expressed some
                  dissatisfaction with having to reactivate the automated withdrawal herself and the lack of a
                  notification regarding the auto pay deactivation.<br><br>
                  <strong>4. Issue Identification, Resolution and Recommendation</strong><br>
                  <strong>Issue:</strong><br>
                  Automated withdrawal stopped without customer's knowledge<br>
                  Interest charges applied<br>
                  <strong>Resolution:</strong><br>
                  Interest charges canceled<br>
                  Customer advised to reactivate automated withdrawal manually<br>
                  <strong>Recommendation:</strong><br>
                  Hydro Ottawa could consider implementing a notification system to inform customers when their auto pay
                  is about to be deactivated due to inactivity.<br><br>
                  <strong>5. Agent Performance</strong><br>
                  The agent demonstrated good customer service skills. They were:<br>
                  Empathetic: Understood the customer's concerns and acknowledged her frustration.<br>
                  Knowledgeable: Provided accurate and timely information about the issue and resolution.<br>
                  Problem-solving: Canceled the interest charges and provided solutions to the customer's concerns.<br>
                  Responsive: Addressed the customer's questions and requests promptly.
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="success" class="mt-6">
                  The call summary was accurate. The call was successfully classified as a billing inquiry. The
                  sentiment of the call was positive, and the issue was resolved to the customer's satisfaction. The
                  customer service representative demonstrated good performance and professionalism.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>OpenAI: Whisper + ChatGPT 4</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription (Whisper)</h3>
                <p>
                  Hi, this is Richard from Hydro Ottawa. How can I help you? Hi Richard, this is Diane. I received a
                  bill. My bills are online, my invoices. And the last one I just received, there was a large amount and
                  I wondered why. And then noticed that our automated withdrawal stopped all of a sudden last November.
                  I see, okay. And we didn't do anything to stop that. We had moved in July and we had called and got
                  the address changed and done all that. So now we've been charged some interest and I'd like to have
                  that looked at and the interest removed so we can just pay what we're owing. Okay, let's see here. Can
                  I see the address? It's 3478 Greens Road. Is that G-R-E-N-S? Apostrophe S. Okay. And is it Diane? Yes.
                  Okay, and can I verify the date of birth? September 3, 1960. Okay, and can I verify the phone number
                  on the file? 613-990-7715. That might be one of them. Okay. Yeah, I see there was a last payment
                  received was way back in October and it looks like there was three bills altogether. But the interest,
                  honestly, wasn't that much. It was only $1.52. Yeah, I know, but I mean the fact that we didn't make
                  this change, I don't really feel we should pay any interest. I'd like to get it rectified. I could
                  take the $1.52 off. It's fine. Yeah, okay, great. Can you see why the automated withdrawal was turned
                  off? Because we've had this for like 20 years plus. Okay, one second. Okay, so it looks like, oh,
                  yeah, there you go. I canceled the interest. That's fine. Okay. So now the remaining balance is
                  $254.70. The reason why the auto pay was canceled, yeah, since it has, so it was from 2007 until 2023.
                  So, yeah, so in the system it always, after so many years, it stops the auto pay after so many years.
                  Okay, well, there should be some sort of notice or something, right? When you first sign up for the
                  auto pay on the form, it says that after a while you have to be. That's a long time. I know. That's a
                  long time to know. And because it's automated payments, you know, and I've kept every invoice and
                  notice from Hydro Ottawa, nothing like that. Just a suggestion, it might be good to set up some sort
                  of notice that tells people that. Yeah, yeah. Okay, I think we'll do that. Okay, so, yeah, the
                  remaining balance now is only $254.70. Okay. Okay, so can you automatically withdraw that or do I have
                  to make a payment? Oh, you have to make a payment manually for this one, yes. Uh-huh, figured that.
                  Okay. All right, so can you reactivate the automated withdrawal for the next bill? Okay, so you'd have
                  to do that. I can't activate it. The customer has to do that. Okay, I'm online, so where do I go to do
                  that? Let me see here one second. I'm not happy about that. Okay, no, never mind. Okay, I did it
                  myself here. Okay, I just extended the date now to, so now it ends in 2027. Okay. Okay, yeah, there
                  you go. Okay, now it's active again. Yeah, but this has to be paid manually. Okay, so when will I see
                  the revised amount so that I can pay the bill? Because I just refreshed my site and it's not. You
                  should see it on there within, give it like an hour or so to update the system. Okay. Okay. Okay.
                  Okay, thanks very much. No worries. You have yourself a good one. Okay. Okay, you too. Bye now. Bye
                  now.
                </p>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (ChatGPT4)</h3>
                  <strong>Detailed Summary</strong><br>
                  Diane called Hydro Ottawa regarding an unexpectedly large amount on her latest bill. She discovered
                  that her automated withdrawal for bill payments, which had been set up for over 20 years, had
                  unexpectedly stopped in November, following their move in July, even though they had updated their
                  address with Hydro Ottawa. This resulted in her being charged interest. Diane requested the interest
                  be waived and the issue with the automated withdrawal to be resolved. The customer service
                  representative, Richard, confirmed her details and found that the interest amounted to $1.52, which he
                  then waived. He explained that the automated withdrawal was stopped because the system cancels auto
                  payments after a certain number of years. Diane expressed dissatisfaction with the lack of notice
                  about this automatic cancellation. Richard reactivated the automated withdrawal, extending it to 2027,
                  but noted that the current outstanding balance would need to be paid manually. He assured Diane that
                  the updated balance would reflect on her account within an hour.<br><br>
                  <strong>Topic Categorization</strong><br>
                  Billing<br><br>
                  Also, the call could be categorized under the following topics:<br>
                  <ul>
                    <li>Billing: The call primarily concerns a billing issue related to unexpected charges and interest.
                    </li>
                    <li>Account Maintenance: Diane's call involves updating her billing preferences and resolving issues
                      related to her account following a move.</li>
                    <li>Pre-Authorized Payment Plan: The issue also pertains to the automated withdrawal payment plan,
                      its unexpected cancellation, and reactivation.</li>
                    <li>Complaint & Escalations: Diane expressed dissatisfaction with the lack of notice about the
                      automatic cancellation of her payment plan, which could be viewed as a complaint.</li>
                  </ul>
                  <br><br>
                  <strong>Sentiment Analysis</strong><br>
                  Customer Sentiment: Initially, Diane's sentiment was one of confusion and slight frustration due to
                  the unexpected charge and the stoppage of the automated payment. However, her tone seemed to become
                  more positive after Richard resolved the interest charge issue and reactivated the auto-payment,
                  despite the inconvenience of having to make a manual payment for the current bill.<br>
                  Agent Sentiment: Richard maintained a helpful and accommodating demeanor throughout the call, promptly
                  addressing Diane's concerns, offering solutions, and ultimately resolving the issues raised.<br><br>
                  <strong>Issue Identification, Resolution, and Recommendation</strong><br>
                  <strong>Issue Identification:</strong> The primary issue was the unexpected cessation of the automated
                  withdrawal for bill payments, leading to an interest charge.<br>
                  <strong>Resolution:</strong> Richard waived the interest charge and reactivated the automated
                  withdrawal for future payments.<br>
                  <strong>Recommendation:</strong> It would be beneficial for Hydro Ottawa to implement a notification
                  system to alert customers before the automatic cancellation of their payment plans. This would prevent
                  similar issues and improve customer satisfaction.<br><br>
                  <strong>Agent Performance</strong><br>
                  Richard demonstrated effective customer service by quickly understanding Diane's issues, verifying her
                  account details, and providing immediate solutions. He successfully resolved the interest charge and
                  reactivated the automated payment plan, meeting the customer's needs. However, there is room for
                  improvement in preventing such issues from arising, such as better communication about policy
                  regarding automatic payment plan cancellations. His performance was efficient and solution-oriented,
                  which likely contributed to a positive customer experience for Diane.
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="success" class="mt-6">
                  This combination of Whisper and ChatGPT4 demonstrated the most understanding of the call.
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Hybrid: Whisper + Claude 3</v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Transcription (OpenAI Whisper)</h3>
                <p>
                  Hi, this is Richard from Hydro Ottawa. How can I help you? Hi Richard, this is Diane. I received a
                  bill. My bills are online, my invoices. And the last one I just received, there was a large amount and
                  I wondered why. And then noticed that our automated withdrawal stopped all of a sudden last November.
                  I see, okay. And we didn't do anything to stop that. We had moved in July and we had called and got
                  the address changed and done all that. So now we've been charged some interest and I'd like to have
                  that looked at and the interest removed so we can just pay what we're owing. Okay, let's see here. Can
                  I see the address? It's 3478 Greens Road. Is that G-R-E-N-S? Apostrophe S. Okay. And is it Diane? Yes.
                  Okay, and can I verify the date of birth? September 3, 1960. Okay, and can I verify the phone number
                  on the file? 613-990-7715. That might be one of them. Okay. Yeah, I see there was a last payment
                  received was way back in October and it looks like there was three bills altogether. But the interest,
                  honestly, wasn't that much. It was only $1.52. Yeah, I know, but I mean the fact that we didn't make
                  this change, I don't really feel we should pay any interest. I'd like to get it rectified. I could
                  take the $1.52 off. It's fine. Yeah, okay, great. Can you see why the automated withdrawal was turned
                  off? Because we've had this for like 20 years plus. Okay, one second. Okay, so it looks like, oh,
                  yeah, there you go. I canceled the interest. That's fine. Okay. So now the remaining balance is
                  $254.70. The reason why the auto pay was canceled, yeah, since it has, so it was from 2007 until 2023.
                  So, yeah, so in the system it always, after so many years, it stops the auto pay after so many years.
                  Okay, well, there should be some sort of notice or something, right? When you first sign up for the
                  auto pay on the form, it says that after a while you have to be. That's a long time. I know. That's a
                  long time to know. And because it's automated payments, you know, and I've kept every invoice and
                  notice from Hydro Ottawa, nothing like that. Just a suggestion, it might be good to set up some sort
                  of notice that tells people that. Yeah, yeah. Okay, I think we'll do that. Okay, so, yeah, the
                  remaining balance now is only $254.70. Okay. Okay, so can you automatically withdraw that or do I have
                  to make a payment? Oh, you have to make a payment manually for this one, yes. Uh-huh, figured that.
                  Okay. All right, so can you reactivate the automated withdrawal for the next bill? Okay, so you'd have
                  to do that. I can't activate it. The customer has to do that. Okay, I'm online, so where do I go to do
                  that? Let me see here one second. I'm not happy about that. Okay, no, never mind. Okay, I did it
                  myself here. Okay, I just extended the date now to, so now it ends in 2027. Okay. Okay, yeah, there
                  you go. Okay, now it's active again. Yeah, but this has to be paid manually. Okay, so when will I see
                  the revised amount so that I can pay the bill? Because I just refreshed my site and it's not. You
                  should see it on there within, give it like an hour or so to update the system. Okay. Okay. Okay.
                  Okay, thanks very much. No worries. You have yourself a good one. Okay. Okay, you too. Bye now. Bye
                  now.
                </p>
                <v-sheet color="grey lighten-4 mt-6 pa-6">
                  <h3 class="mb-6">Classification (Claude 3)</h3>
                  <strong>1. Detailed Summary of the Call</strong><br>
                  The customer, Diane, received a higher bill and noticed her automated bill payment had stopped in
                  November after moving in July. She called to get the interest charges removed and understand why the
                  automated payment was cancelled. The agent, Richard, verified her information, removed the $1.52
                  interest charge, and explained their policy of cancelling autopay after many years, though Diane was
                  unaware of this policy. Richard acknowledged the need for better notification. The remaining balance
                  was $254.70, to be paid manually. Diane reactivated autopay online herself, extending it to 2027. The
                  revised bill amount would update within an hour.
                  <strong>2. Topic Categorization</strong><br>
                  Billing, Automated Payment<br><br>
                  <strong>3. Sentiment Analysis:</strong><br>
                  Diane seemed frustrated initially about the unexpected charges and lack of notification. However, her
                  sentiment improved as Richard resolved the issue professionally.<br><br>
                  <strong>4. Issue Identification, Resolution and Recommendation</strong><br>
                  <strong>Issue:</strong><br>
                  <ol>
                    <li>Higher than expected bill</li>
                    <li>Automated payment unexpectedly cancelled</li>
                    <li>Interest charges due to missed payments</li>
                  </ol><br><br>
                  <strong>Resolution:</strong><br>
                  <ol>
                    <li>Interest charge of $1.52 removed</li>
                    <li>Explained autopay cancellation policy, though better notification needed</li>
                    <li>$254.70 balance to be paid manually</li>
                    <li>Customer reactivated autopay online until 2027</li>
                  </ol><br><br>
                  <strong>Recommendation:</strong><br>
                  Implement a notification system to inform customers when autopay will be cancelled per policy, well in
                  advance.<br><br>
                  <strong>5. Agent Performance</strong><br>
                  Richard handled the call professionally, providing clear explanations and resolving concerns. However,
                  he could have offered to reactivate autopay for the customer.<br><br>
                </v-sheet>
                <h3 class="mt-6">Result</h3>
                <v-alert type="warning" class="mt-6">
                  The call summary was mostly accurate
                </v-alert>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Pipeline ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Pipeline</h2>
          <v-timeline dense>
            <v-timeline-item v-for="(step, i) in pipeline" :key="i" :color="step.colour" small :icon="step.icon">
              <template>
                <span :class="`headline font-weight-bold ${step.text_colour}`" v-text="step.title"></span>
              </template>
              <div class="py-4">
                <h2 :class="`headline font-weight-light mb-4 ${step.text_colour}`">
                  {{ step.subtitle }}
                </h2>
                <div>
                  {{ step.description }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- ------------ Status ------------ -->
    <!-- <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Status</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>
            <div class="text-h6 text-center ml-12">Gain access to full data set</div>
          </v-sheet>
          <ul>
            <li class="text-decoration-line-through">Obtain sample audio files</li>
            <li class="text-decoration-line-through">Analyze speech-to-text models</li>
            <li class="text-decoration-line-through">Analyze LLM models</li>
            <li class="text-decoration-line-through">Process sample files</li>
            <li class="text-decoration-line-through">Share results</li>
            <li class="text-decoration-line-through">Develop pipeline</li>
            <li>Gain access to full data set</li>
            <li class="text-decoration-line-through">Present to EMT</li>
            <li class="text-decoration-line-through">Process 1 year of data</li>
            <li class="text-decoration-line-through">Create interactive app</li>
            <li>Develop dashboard</li>
            <li>Develop trends</li>
          </ul>
          <h3 class="mt-12 mb-6">March 7, 2024</h3>
          <p>
            Currently, awaiting transfer of the entire data set. Exploring the possibility of using Snowflake to compute
            the
            Whisper model.
          </p>
        </v-card-text>
      </v-card>
    </v-tab-item> -->

    <!-- ------------ Conclusion ------------ -->
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <h2 class="mb-6">Conclusion</h2>
          <v-sheet color="grey lighten-4 pa-4 mb-6" rounded>

          </v-sheet>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
<script>
export default {
  name: 'CallAnalysisBackground',
  data() {
    return {
      // Pipeline
      pipeline: [
        {
          title: 'Fetch Call Recording',
          subtitle: 'Google Cloud Function',
          description: 'Call recordings are located in Snowflake. This function fetches the recording audio file based on the call ID.',
          colour: 'blue',
          text_colour: 'blue--text',
          icon: '',
        },
        {
          title: 'Convert Audio Format',
          subtitle: 'Google Cloud Platform Extension',
          description: 'Telstrat provides call recordings in a specific WAV format which is not supported by Whisper. This extension converts the audio file to MP3.',
          colour: 'red',
          text_colour: 'red--text',
          icon: '',
        },
        {
          title: 'Transcribe',
          subtitle: 'OpenAI Whisper',
          description: 'The audio file is transcribed to text using OpenAI Whisper which is a speech-to-text product.',
          colour: 'yellow darken-2',
          text_colour: 'text--darken-2 yellow--text',
          icon: '',
        },
        {
          title: 'Large Language Model Analysis',
          subtitle: 'Google Gemini 1.0 Pro',
          description: 'The transcribed text is analyzed using Google Gemini 1.0 Pro to understand the sentiment, issue, resolution, and recommendation.',
          colour: 'blue',
          text_colour: 'blue--text',
          icon: '',
        },
        {
          title: 'Record Data',
          subtitle: 'Google Cloud Platform (Firestore)',
          description: 'The processed data is recorded in Firestore for future reference and analysis.',
          colour: 'green',
          text_colour: 'green--text',
          icon: '',
        },
        {
          title: 'Response',
          subtitle: 'Google Cloud Platform Function',
          description: 'The status of the process is sent back to the client.',
          colour: 'red',
          text_colour: 'red--text',
          icon: '',
        },
      ],
    }
  },
  methods: {
    showUseCaseDialog(index) {
      this.selectedUseCase = this.usecases[index];
      this.useCaseDialog = true;
    },
  }
}
</script>
<style lang="scss" scoped></style>