<template>
    <v-container>
      <h1 class="mb-4">Agent Assist</h1>
      <div class="d-flex flex-row">
        <v-card class="d-flex flex-column flex-1">
          <v-tabs vertical v-model="tab">
            <v-tab>Dashboard</v-tab>
            <v-tab>Knowledgebase</v-tab>
            <v-tab>Tools</v-tab>
            <v-tab>Coaching</v-tab>
            <v-tab disabled>Training</v-tab>
            <v-tab>Background</v-tab>
          </v-tabs>
        </v-card>
        <div class="d-flex flex-column flex-3 mx-6">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="d-flex flex-row">
                <v-text-field 
                  v-model="enquiry" 
                  label="Ask a question" 
                  solo 
                  placeholder="Search" 
                  @keyup.enter="search"
                  prepend-inner-icon="mdi-creation"
                  append-icon="mdi-keyboard-return"
                  hide-details
                  class="animated-border width-100 flex-1"
                  style="flex: 1"
                ></v-text-field>  
              </div>
              <div class="d-flex flex-column justify-center align-center mt-12">
                <div class="overline">Common Questions</div>
                <div class="body-2">Search the knowledgebase for answers to common questions</div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-row">
                Knowledgebase
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-row">
                Tools
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-row">
                Coaching
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-row">
                Training
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-row">
                Background
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div class="d-flex flex-column flex-1">
          <v-card class="recording-card d-flex justify-center align-center" :style="{ background: recording ? 'black' : 'white' }">
            <v-btn fab large elevation="1" class="ml-2" :color="recording ? 'red' : 'black'" dark 
              @mousedown="startRecording" 
              @mouseup="stopRecording"
              @mouseleave="stopRecording">
              <v-icon>mdi-microphone</v-icon>
            </v-btn>
          </v-card>
          <div class="overline mt-8">Quick Actions</div>
          <v-card>
            <v-list>
              <v-list-item dense link>
                <v-list-item-title>Process Payment</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense link>
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense link>
                <v-list-item-title>Reconnect Service</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
      </div>
    </v-container>
  </template>
<script>
  export default {
    name: 'CustomerServiceAgentAssist',
    data: () => ({
      tab: 0,
      enquiry: '',
      recording: false,
    }),
    methods: {
      startRecording() {
        this.recording = true;
      },
      stopRecording() {
        this.recording = false;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .animated-border {
    padding: 4px;
    border-radius: 4px;
    background: linear-gradient(270deg, #bfd7e2, #edca9b);
    background-size: 400% 400%;
    animation: gradientAnimation 4s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .recording-card {
    min-height: 120px;
  }
</style>