<template>
  <v-container>
    <h1 class="mb-4">Switch Orders</h1>
    <v-card>
      <v-tabs v-model="tab" class="px-4 pt-4">
        <v-tab>Dashboard</v-tab>
        <v-tab>Explorer</v-tab>
        <v-tab>Review</v-tab>
        <v-tab>OTOs</v-tab>
        <v-tab>Details</v-tab>
        <v-tab>Proof of Concept</v-tab>
        <v-tab>Background</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <!-- ---------------------- Dashboard ---------------------- -->
          <v-tab-item>
            <img :src="images.dashboard" width="100%" height="100%">
          </v-tab-item>
          <!-- ---------------------- Explorer ---------------------- -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column data-column">
                <v-text-field
                v-model="searchQuery"
                label="Search by Job ID"
                prepend-inner-icon="mdi-magnify"
                clearable
                class="mb-2"
                :loading="searching"
                @input="debouncedSearch"
                filled
                hide-details
              ></v-text-field>
              <v-treeview
                v-model="active"
                :items="treeItems"
                :open.sync="open"
                activatable
                open-on-click
                item-key="id"
                return-object
                @update:active="handleActiveChange"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon>
                    {{
                      item.type === "job"
                        ? "mdi-folder"
                        : item.type === "document"
                        ? "mdi-file-document"
                        : "mdi-file-document-outline"
                    }}
                  </v-icon>
                </template>
                <template v-slot:label="{ item }">
                  <span :style="{ color: getItemColor(item) }">{{ item.name }}</span>
                </template>
              </v-treeview>
              <div v-if="bulkLoading" class="mt-2">
                <v-progress-linear
                  :value="progressPercentage"
                  height="10"
                  class="mb-2"
                >
                  <strong>{{ progressPercentage }}%</strong>
                </v-progress-linear>
                <v-btn block color="error" @click="stopBulkLoading">
                  Stop
                </v-btn>
              </div>
              <div v-else-if="!searchQuery">
                <v-btn
                  v-if="hasMore && !loading"
                  @click="loadMoreJobs"
                  block
                  class="mt-2"
                  :disabled="loading"
                >
                  Load Next
                </v-btn>
                <v-btn
                  v-if="hasMore && !loading"
                  @click="loadAllJobs"
                  block
                  class="mt-2"
                  :disabled="loading"
                >
                  Load All
                </v-btn>
              </div>
              <v-progress-linear
                v-else-if="loading"
                indeterminate
                class="mt-2"
              ></v-progress-linear>
              </div>
              <div class="d-flex ml-4 flex-1 the-thin-grey-border grey lighten-4 flex-column">
                <div>
                  <v-tabs v-model="selectedTab">
                    <v-tab :disabled="!imagePreview">Preview</v-tab>
                    <v-tab :disabled="!documentPreview">Document</v-tab>
                    <v-tab>Details</v-tab>
                  </v-tabs>
                </div>
                <v-divider></v-divider>
                <v-tabs-items v-model="selectedTab" class="grey lighten-4" style="height: 100%">
                  <v-tab-item class="text-left">
                    <img v-if="imagePreview" :src="imagePreview" width="100%" height="100%">
                  </v-tab-item>
                  <v-tab-item class="text-left" style="height: 100%;">
                    <iframe v-if="documentPreview" :src="documentPreview" width="100%" height="100%"></iframe>
                  </v-tab-item>
                  <v-tab-item class="text-left">
                    <pre>{{ selectedPage }}</pre>
                    <pre>{{ selectedDocument }}</pre>
                    <pre>{{ selectedJob }}</pre>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </v-tab-item>
          <!-- ---------------------- Review ---------------------- -->
          <v-tab-item>
            <SwitchOrdersReview />
          </v-tab-item>
          <!-- ---------------------- OTOs ---------------------- -->
          <v-tab-item>
            <SwitchOrdersOTOs />
          </v-tab-item>
          <!-- ---------------------- Details ---------------------- -->
          <v-tab-item>
            <v-img :src="images.details" width="100%" height="100%" @click="detailsWindowDialog = true"></v-img>
          </v-tab-item>
          <!-- ---------------------- Proof of Concept ---------------------- -->
          <v-tab-item>
            <SwitchOrdersConcept />
          </v-tab-item>
          <!-- ---------------------- Background ---------------------- -->
          <v-tab-item>
            <SwitchOrdersBackground />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="detailsWindowDialog" width="900">
      <v-card>
        <v-img :src="images.window" width="100%" height="100%"></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  where,
  getCountFromServer
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import debounce from "lodash/debounce";
import SwitchOrdersBackground from "@/components/Operations/SwitchOrdersBackground.vue";
import SwitchOrdersConcept from "@/components/Operations/SwitchOrdersConcept.vue";
import SwitchOrdersReview from "@/components/Operations/SwitchOrdersReview.vue";
import SwitchOrdersOTOs from "@/components/Operations/SwitchOrdersOTOs.vue";
export default {
  name: "SwitchOrderExplorer",
  components: {
    SwitchOrdersBackground,
    SwitchOrdersConcept,
    SwitchOrdersReview,
    SwitchOrdersOTOs
  },
  data() {
    return {
      tab: 0,
      selectedTab: 0,
      detailsWindowDialog: false,
      searchQuery: "",
      imagePreview: "",
      documentPreview: "",
      jobs: [],
      active: [],
      open: [],
      loading: false,
      searching: false,
      lastDoc: null,
      hasMore: true,
      pageSize: 100,
      totalJobs: 0,
      bulkLoading: false,
      stopBulk: false,
      selectedJob: null,
      selectedDocument: null,
      selectedPage: null,
      selectedTab: null,
      images: {
        dashboard: null,
        details: null,
        window: null
      }
    };
  },
  computed: {
    treeItems() {
      return this.jobs.map((job) => {
        // Check if the job has any "GOOD" documents for coloring purposes
        const hasGoodDocument = job.jobData.documents.some(
          (doc) => doc.classification === "GOOD"
        );
        return {
          id: job.id,
          name: job.jobData.job_id,
          type: "job",
          data: job.jobData,
          hasGoodDocument: hasGoodDocument,
          children: job.jobData.documents.map((doc, docIdx) => {
            const docNode = {
              id: `doc-${job.id}-${docIdx}`,
              name: doc.source_filename,
              type: "document",
              data: doc,
              parentJob: job.jobData,
              children: [],
            };
            // Add page nodes only for "GOOD" documents with a pages array
            if (
              doc.classification === "GOOD" &&
              doc.pages &&
              doc.pages.length > 0
            ) {
              docNode.children = doc.pages.map((page, pageIdx) => ({
                id: `page-${job.id}-${docIdx}-${pageIdx}`,
                name: `Page ${pageIdx + 1} - ${page.type || "Unknown"}`,
                type: "page",
                data: {
                  parentJob: job.jobData,
                  parentDocument: doc,
                  pageData: page,
                },
              }));
            }
            return docNode;
          }),
        };
      });
    },
    progressPercentage() {
      return this.totalJobs > 0
        ? Math.floor((this.jobs.length / this.totalJobs) * 100)
        : 0;
    }
  },
  methods: {
    async loadJobs() {
      if (this.loading) return;
      this.loading = true;
      try {
        const db = getFirestore();
        let jobsQuery;
        if (this.lastDoc) {
          jobsQuery = query(
            collection(db, "op_switchorders_jobs"),
            orderBy("job_id"),
            startAfter(this.lastDoc),
            limit(this.pageSize)
          );
        } else {
          jobsQuery = query(
            collection(db, "op_switchorders_jobs"),
            orderBy("job_id"),
            limit(this.pageSize)
          );
        }
        const jobsSnapshot = await getDocs(jobsQuery);
        const newJobs = jobsSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          jobData: docSnap.data()
        }));
        this.jobs = [...this.jobs, ...newJobs];
        this.lastDoc = jobsSnapshot.docs[jobsSnapshot.docs.length - 1] || this.lastDoc;
        this.hasMore = jobsSnapshot.docs.length === this.pageSize;
      } catch (error) {
        console.error("Error loading jobs:", error);
      } finally {
        this.loading = false;
      }
    },
    async loadMoreJobs() {
      if (this.hasMore && !this.searchQuery) {
        await this.loadJobs();
      }
    },
    async loadAllJobs() {
      if (this.loading || this.searchQuery) return;
      this.bulkLoading = true;
      this.stopBulk = false;
      this.loading = true;
      try {
        const db = getFirestore();
        const countQuery = query(collection(db, "op_switchorders_jobs"));
        const countSnapshot = await getCountFromServer(countQuery);
        this.totalJobs = countSnapshot.data().count;
        while (this.hasMore && !this.stopBulk) {
          await this.loadJobs();
          await new Promise((resolve) => setTimeout(resolve, 50)); // Allow UI updates
        }
      } catch (error) {
        console.error("Error loading all jobs:", error);
      } finally {
        this.bulkLoading = false;
        this.loading = false;
      }
    },
    stopBulkLoading() {
      this.stopBulk = true;
    },
    async searchJobs() {
      if (!this.searchQuery) {
        this.jobs = [];
        this.lastDoc = null;
        this.hasMore = true;
        await this.loadJobs();
        return;
      }
      this.searching = true;
      try {
        const db = getFirestore();
        const searchQueryRef = query(
          collection(db, "op_switchorders_jobs"),
          where("job_id", ">=", this.searchQuery),
          where("job_id", "<=", this.searchQuery + "\uf8ff"),
          orderBy("job_id"),
          limit(50)
        );
        const jobsSnapshot = await getDocs(searchQueryRef);
        this.jobs = jobsSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          jobData: docSnap.data()
        }));
        this.hasMore = false;
      } catch (error) {
        console.error("Error searching jobs:", error);
      } finally {
        this.searching = false;
      }
    },
    debouncedSearch: debounce(function () {
      this.jobs = [];
      this.lastDoc = null;
      this.hasMore = true;
      this.searchJobs();
    }, 300),
    async handleActiveChange(newActive) {
      if (!newActive || !newActive.length) {
        this.selectedJob = null;
        this.selectedDocument = null;
        this.selectedPage = null;
        return;
      }
      const selected = newActive[0];
      if (selected.type === "job") {
        this.selectedJob = this.jobs.find(job => job.id === selected.id) || null;
        this.selectedDocument = null;
        this.selectedPage = null;
        this.imagePreview = null;
      } else if (selected.type === "document") {
        this.selectedJob = this.jobs.find(job => job.id === selected.parentJob.id) || null;
        this.selectedDocument = { ...selected.data };
        this.selectedTab = 1;
        this.imagePreview = null;
      } else if (selected.type === "page") {
        this.selectedJob = this.jobs.find(job => job.id === selected.data.parentJob.id) || null;
        this.selectedDocument = selected.data.parentDocument;
        this.selectedPage = selected.data.pageData;
        this.imagePreview = await this.getImageUrl(selected.data.pageData.jpg_filepath);
        this.documentPreview = await this.getDocumentUrl(this.selectedDocument.source_filepath);
        this.selectedTab = 0;
      }
    },
    getItemColor(item) {
      if (item.type === "job") {
        return item.hasGoodDocument ? "green" : "black";
      } else if (item.type === "document") {
        switch (item.data.classification) {
          case "GOOD":
            return "green";
          case "BAD":
            return "red";
          default:
            return "grey";
        }
      }
      return "black"; // Pages are black by default
    },
    async getImageUrl(path) {
      try {
        if (path.startsWith("gs://hydroottawa-switchorders")) { // gs://hydroottawa-switchorders/0-pages/0132-1-1.jpg
          path = path.replace("gs://hydroottawa-switchorders/0-pages", "operations/switchorders/pages");
        }
        const storage = getStorage();
        const fileRef = ref(storage, path);
        return await getDownloadURL(fileRef);
      } catch (error) {
        console.error("Error loading images:", error);
        throw error;
      }
    },
    async getDocumentUrl(path) {
      console.log("getDocumentUrl", path);
      try {
        if (path.startsWith("0-raw")) { // gs://hydroottawa-switchorders/0-pages/0132-1-1.jpg
          path = path.replace("0-raw", "operations/switchorders/dataset");
          console.log("FOUND IT", path);  
        }
        const storage = getStorage();
        const fileRef = ref(storage, path);
        return await getDownloadURL(fileRef);
      } catch (error) {
        console.error("Error loading images:", error);
        throw error;
      }
    },
    async loadImages() {
      try {
        this.images.dashboard = await this.getImageUrl('operations/switchorders/UI/step-1.jpg');
        this.images.details = await this.getImageUrl('operations/switchorders/UI/step-2.jpg');
        this.images.window = await this.getImageUrl('operations/switchorders/UI/step-3.jpg');
      } catch (error) {
        console.error("Error loading images:", error);
      }
    },
  },
  created() {
    this.loadJobs();
    this.loadImages();
  },
};
</script>

<style scoped>
.data-column {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.word-wrap {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}
.the-thin-grey-border {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}
.v-text-field--filled {
  flex: 0 !important;
}
</style>